export const ADD_BUSINESSES = "ADD_BUSINESSES";
export const GET_BUSINESSES = "GET_BUSINESSES";
export const GET_BUSINESSES_ERROR = "GET_BUSINESSES_ERROR";
export const UPDATE_BUSINESSES_STATUS = "UPDATE_BUSINESSES_STATUS";

function globalReducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_BUSINESSES_STATUS: {
      return {
        ...state,
        businesses: state.businesses.map((business) => {
          if (business.id === payload.id) {
            return {
              ...business,
              isActive: payload.status,
            };
          }

          return business;
        }),
      };
    }

    case ADD_BUSINESSES: {
      return {
        ...state,
        businesses: state.businesses.concat([payload]),
      };
    }

    case GET_BUSINESSES: {
      return {
        ...state,
        businesses: payload,
        businessesError: null,
      };
    }

    case GET_BUSINESSES_ERROR: {
      return {
        ...state,
        businessesError: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export default globalReducer;
