import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Row, Col } from '../../components/Grid';
import PageNotFoundIMG from '../../assets/images/404.jpg';
import logo from '../../assets/images/kidsbuffet-logo.svg';
import './index.sass';

const PageNotFound = () => (
  <>
    <Helmet>
      <title>Página não encontrada - KidsBuffets Admin</title>
    </Helmet>

    <div className="pageNotFound">
      <div className="pageNotFound-content">
        <Row cellSpacing={0} rowSpacing={35}>
          <Col
            lg={12}
            md={12}
            sm={12}
            xs={12}
            horizontalAlign="center"
          >
            <Link to="/">
              <img src={logo} alt="KidsBuffets" />
            </Link>
          </Col>
        </Row>

        <Row cellSpacing={40} rowSpacing={15} verticalAlign="center">
          <Col
            lg={7}
            md={7}
            sm={12}
            xs={12}
            horizontalAlign="flex-end"
            className="pageNotFound-col-img"
          >
            <img src={PageNotFoundIMG} alt="Página não encontrada!" />
          </Col>

          <Col
            lg={5}
            md={5}
            sm={12}
            xs={12}
            verticalAlign="center"
          >
            <h1 className="pageNotFound-title">OOOPS... Não chore.</h1>
            <p className="pageNotFound-description">É apenas uma página não encontrada.</p>
            <p className="pageNotFound-description">Talvez o conteúdo que você está tentando acessar pode ter sido removido ou substituído.</p>

            <div className="pageNotFound-btn">
              <Link to="/" className="btn blue-theme">voltar para a home</Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </>
);

export default PageNotFound;
