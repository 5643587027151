import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import { Link } from "react-router-dom";
import "./index.sass";

/*
  [{ name: 'New Page', url: '' }]
*/

const Breadcrumb = ({ items }) => {
  if (items && items.length > 0) {
    return (
      <ul id="breadcrumb">
        <li>
          <Link to="/meus-anuncios">Meus anúncios</Link>
        </li>

        {items &&
          items.map((item) => (
            <li key={uuidv1()}>
              {item.url ? (
                <Link to={item.url}>{item.name}</Link>
              ) : (
                <strong>{item.name}</strong>
              )}
            </li>
          ))}
      </ul>
    );
  }

  return <span />;
};

Breadcrumb.propTypes = {
  items: PropTypes.instanceOf(Array),
};

Breadcrumb.defaultProps = {
  items: [],
};

export default Breadcrumb;
