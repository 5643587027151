import { APIResquest } from "../utils/api";
import { REACT_APP_ORIGIN } from "../config/variables";

export function detailsNotRegisteredBusiness(token, buffetId) {
  return APIResquest({
    uri: "super-admin/notRegisteredBuffetDetails",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
    },
  });
}

export function getAllNotRegisteredBusiness(data) {
  return APIResquest({
    uri: "super-admin/notRegisteredBuffets",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: data,
  });
}

export function saveNotRegisteredBusiness(data) {
  return APIResquest({
    uri: "super-admin/notRegisteredBuffets",
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    data,
  });
}

export function removeNotRegisteredBusiness(token, buffetId) {
  return APIResquest({
    uri: "super-admin/notRegisteredBuffets",
    method: "DELETE",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
    },
  });
}

export function sendEmailNotRegisteredBusiness(token, buffetId) {
  return APIResquest({
    uri: "super-admin/notRegisteredBuffetsSendEmail",
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    data: {
      token,
      buffetId,
    },
  });
}
