import React, { useContext } from "react";
import { Row, Col } from "../../../components/Grid";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import RegisterContext from "../../../context/register";

const BuffetAd = () => {
  const {
    onHandleChange,
    errors,
    buffetDetails: { customerService, description, capacity },
  } = useContext(RegisterContext);

  return (
    <StatsBlock title="Dados do anúncio">
      <Row cellSpacing={15} rowSpacing={15}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="select"
            label="Capacidade máxima *"
            id="txtCapacity"
            name="capacity"
            defaultValue={capacity}
            inputErrors={errors}
            ref={onHandleChange({
              required: true,
            })}
          >
            <option value="">Selecione...</option>
            <option value="75">até 75 pessoas</option>
            <option value="100">até 100 pessoas</option>
            <option value="125">até 125 pessoas</option>
            <option value="150">até 150 pessoas</option>
            <option value="200">até 200 pessoas</option>
            <option value="250">até 250 pessoas</option>
            <option value="300">até 300 pessoas</option>
            <option value="400">até 400 pessoas</option>
          </TextField>
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="textarea"
            label="Informações de atendimento *"
            rows={3}
            id="txtCustomerService"
            name="customerService"
            maxLength={250}
            placeholder="De segunda à sexta, das 9h00 às 19h00. Sábados, domingos e feriados somente com horário marcado..."
            ref={onHandleChange({
              required: true,
              minLength: 3,
            })}
            defaultValue={customerService}
            inputErrors={errors}
          />
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="textarea"
            label="Fale um pouco sobre seu Buffet Infantil *"
            id="txtDescription"
            name="description"
            placeholder="conte nos sua história..."
            rows={8}
            maxLength={5000}
            defaultValue={description}
            ref={onHandleChange({
              required: true,
              minLength: 3,
            })}
            inputErrors={errors}
          />
        </Col>
      </Row>
    </StatsBlock>
  );
};

export default BuffetAd;
