import React, { useContext } from "react";
import { Row, Col } from "../../../components/Grid";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import RegisterContext from "../../../context/register";

const BuffetSocial = () => {
  const {
    onHandleChange,
    buffetDetails: { phone, email_to_receive_budgets, whatsapp },
  } = useContext(RegisterContext);

  return (
    <StatsBlock title="Dados de contato">
      <Row cellSpacing={15} rowSpacing={15}>
        <Col lg={4} md={6} sm={6} xs={12}>
          <TextField
            type="text"
            label="Telefone Comercial (fixo)"
            id="txtPhone"
            name="phone"
            mask="(99) 9999-9999"
            placeholder="(11) 9999-9999"
            autoComplete="off"
            defaultValue={phone}
            ref={onHandleChange({
              required: false,
            })}
          />
        </Col>

        <Col lg={4} md={6} sm={6} xs={12}>
          <TextField
            type="text"
            label="Celular/Whatsapp"
            id="txtWhatsapp"
            name="whatsapp"
            placeholder="(11) 9999-99999"
            mask="(99) 9999-99999"
            autoComplete="off"
            defaultValue={whatsapp}
            ref={onHandleChange({
              required: false,
            })}
          />
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            label="E-mail para recebimento de Orçamentos"
            id="txtEmailToReceiveBudgets"
            name="email_to_receive_budgets"
            placeholder="Digite o e-mail..."
            autoComplete="off"
            ref={onHandleChange}
            minLength={3}
            maxLength={60}
            defaultValue={email_to_receive_budgets}
            description={`Todos os pedidos de solicitação de orçamento serão encaminhados para o e-mail acima`}
          />
        </Col>
      </Row>
    </StatsBlock>
  );
};

export default BuffetSocial;
