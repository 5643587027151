import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Lightbox from "../../components/Lightbox";

const BudgetsLightbox = ({
  show,
  budgetDetail,
  onHandleOpenBudgetsLightbox,
}) => {
  return (
    <Lightbox
      show={show}
      className="mybudgets-lightboxConfirmation"
      width={680}
      height={500}
      onClose={() => onHandleOpenBudgetsLightbox(false)}
      title="Detalhes do orçamento"
    >
      {budgetDetail && Object.entries(budgetDetail).length > 1 && (
        <table className="mybudgets-table vertical-table">
          <tbody>
            <tr>
              <td width="120">
                <strong>Data</strong>
              </td>
              <td>
                {moment(budgetDetail.created_at).format("DD/MM/YYYY - HH:MM")}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Nome</strong>
              </td>
              <td>{budgetDetail.name}</td>
            </tr>

            <tr>
              <td>
                <strong>E-mail</strong>
              </td>
              <td>{budgetDetail.email}</td>
            </tr>

            <tr>
              <td>
                <strong>Telefone</strong>
              </td>
              <td>{budgetDetail.phone}</td>
            </tr>

            {budgetDetail.quantity && (
              <tr>
                <td>
                  <strong>Nº convidados</strong>
                </td>
                <td>{budgetDetail.quantity}</td>
              </tr>
            )}

            {budgetDetail.partyDate && (
              <tr>
                <td>
                  <strong>Data da festa</strong>
                </td>
                <td>{moment(budgetDetail.partyDate).format("DD/MM/YYYY")}</td>
              </tr>
            )}

            {budgetDetail.message && (
              <tr>
                <td valign="top">
                  <strong>Mensagem</strong>
                </td>
                <td>{budgetDetail.message}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <div className="lightbox-actions">
        <a
          href={`mailto:${budgetDetail.email}?subject=Pedido de orçamento - KidsBuffets&body=Olá ${budgetDetail.name}`}
          className="btn small green-theme"
        >
          Responder
        </a>

        <button
          type="button"
          className="btn small no-theme"
          onClick={() => onHandleOpenBudgetsLightbox(false)}
        >
          Cancelar
        </button>
      </div>
    </Lightbox>
  );
};

BudgetsLightbox.propTypes = {
  show: PropTypes.bool.isRequired,
  budgetDetail: PropTypes.instanceOf(Object).isRequired,
  onHandleOpenBudgetsLightbox: PropTypes.func.isRequired,
};

export default BudgetsLightbox;
