import { useState } from "react";

export default function useLocalStorage() {
  const [localItem, setState] = useState(localStorage.getItem("kidsBuffets"));

  function setLocalItem(newItem) {
    localStorage.setItem("kidsBuffets", JSON.stringify(newItem));
    setState(newItem);
  }

  return [localItem, setLocalItem];
}
