import React, { useContext } from "react";
import { Row, Col } from "../../../components/Grid";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import RegisterContext from "../../../context/register";

const BuffetSocial = () => {
  const {
    onHandleChange,
    onHandleRemoveFile,
    errors,
    buffetDetails: { photos, photo01, photo02, photo03 },
  } = useContext(RegisterContext);

  return (
    <StatsBlock title="Fotos">
      <input type="hidden" name="photos" value={JSON.stringify(photos)} />

      <Row cellSpacing={15} rowSpacing={15}>
        <Col lg={4} md={12} sm={12} xs={12}>
          <TextField
            type="file"
            label="Foto Principal *"
            id="txtPhoto01"
            name="photo01"
            fileimage={
              photos.length > 0 && photos[0].url ? photos[0].url : null
            }
            ref={onHandleChange({
              required: true,
            })}
            onRemove={(photoId) => onHandleRemoveFile(photoId)}
            defaultValue={photo01}
            inputErrors={errors}
            required
          />
        </Col>

        <Col lg={4} md={12} sm={12} xs={12}>
          <TextField
            type="file"
            label="Foto 02"
            id="txtPhoto02"
            name="photo02"
            fileimage={
              photos.length > 1 && photos[1].url ? photos[1].url : null
            }
            ref={onHandleChange}
            onRemove={(photoId) => onHandleRemoveFile(photoId)}
            defaultValue={photo02}
          />
        </Col>

        <Col lg={4} md={12} sm={12} xs={12}>
          <TextField
            type="file"
            label="Foto 03"
            id="txtPhoto03"
            name="photo03"
            fileimage={
              photos.length > 2 && photos[2].url ? photos[2].url : null
            }
            ref={onHandleChange}
            onRemove={(photoId) => onHandleRemoveFile(photoId)}
            defaultValue={photo03}
          />
        </Col>
      </Row>
    </StatsBlock>
  );
};

export default BuffetSocial;
