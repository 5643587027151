import React, { useContext } from "react";
import { Row, Col } from "../../../components/Grid";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import Loader from "../../../components/Loader";
import { validWebsite, validBusinessName } from "../../../helpers";
import RegisterContext from "../../../context/register";

const BuffetData = () => {
  const {
    loadingZipCode,
    editing,
    zipCodeIsValid,
    listOfDistricts,
    onHandleChange,
    onHandleBlurCep,
    errors,
    buffetDetails: {
      name,
      address,
      district,
      district2,
      number,
      city,
      state,
      website,
      zipCode,
    },
  } = useContext(RegisterContext);

  return (
    <StatsBlock title="Dados do Buffet">
      <Row cellSpacing={15} rowSpacing={15}>
        <Col lg={5} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            label="Nome do buffet *"
            id="txtName"
            name="name"
            placeholder="digite o nome do buffet..."
            autoComplete="off"
            title="Não é permitido incluir a palavra 'Buffet' no anúncio."
            ref={onHandleChange({
              required: true,
              minLength: 3,
              pattern: validBusinessName(name).regex,
            })}
            maxLength={60}
            defaultValue={name}
            readOnly={editing === true}
            inputErrors={errors}
          />
        </Col>

        <Col lg={4} md={8} sm={8} xs={12}>
          <TextField
            type="text"
            label="Website"
            id="txtWebsite"
            name="website"
            placeholder="https://kidsbuffets.com.br"
            autoComplete="off"
            defaultValue={website}
            title="URL deve incluir http ou https"
            maxLength={60}
            ref={onHandleChange({
              pattern: validWebsite(website).regex,
            })}
            inputErrors={errors}
          />
        </Col>

        <Col lg={3} md={4} sm={4} xs={12}>
          <TextField
            type="text"
            label={`CEP *`}
            id="txtZipCode"
            name="zipCode"
            placeholder="00000000"
            autoComplete="off"
            onBlur={onHandleBlurCep}
            defaultValue={zipCode}
            minLength={8}
            maxLength={8}
            title="CEP inválido"
            ref={onHandleChange({
              required: true,
              minLength: 8,
              pattern: Boolean(zipCodeIsValid) ? null : /^(true)$/,
            })}
            inputErrors={errors}
          />
        </Col>

        <Col
          lg={12}
          md={12}
          sm={12}
          xs={12}
          verticalAlign="center"
          show={loadingZipCode}
        >
          <Loader containerHeight={50} verticalAlign="center" />
        </Col>

        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode}
        >
          <TextField
            type="text"
            label="Endereço"
            id="txtAddress"
            name="address"
            value={address}
            readOnly
          />
        </Col>

        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode}
        >
          <TextField
            type="text"
            label="Bairro"
            id="txtDistrict"
            name="district"
            value={district}
            readOnly
          />
        </Col>

        <Col
          lg={2}
          md={3}
          sm={3}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode}
        >
          <TextField
            type="text"
            label="Número *"
            id="txtNumber"
            name="number"
            defaultValue={number}
            autoComplete="off"
            placeholder="123"
            maxLength="6"
            ref={onHandleChange({
              required: true,
            })}
          />
        </Col>

        <Col
          lg={4}
          md={9}
          sm={9}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode && listOfDistricts.length > 0}
        >
          <TextField
            type="select"
            label="Selecione sua região"
            id="txtDistrict2"
            name="district2"
            ref={onHandleChange({
              required: true,
            })}
            defaultValue={district2}
          >
            <option value="">Selecione...</option>
            {listOfDistricts.map((item) => (
              <option key={item.ibge} value={item.name}>
                {item.name}
              </option>
            ))}
          </TextField>
        </Col>

        <Col
          lg={4}
          md={9}
          sm={9}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode}
        >
          <TextField
            type="text"
            label="Cidade"
            id="txtCity"
            name="city"
            value={city}
            readOnly
          />
        </Col>

        <Col
          lg={2}
          md={3}
          sm={3}
          xs={12}
          show={zipCodeIsValid && !loadingZipCode}
        >
          <TextField
            type="text"
            label="Estado (UF)"
            id="txtState"
            name="state"
            value={state}
            readOnly
          />
        </Col>
      </Row>
    </StatsBlock>
  );
};

export default BuffetData;
