import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";
import { Row, Col } from "../Grid";

const Paginator = (props) => {
  const refContainer = useRef();

  const [itemsBeingShowed] = useState(props.itemsBeingShowed);

  // const onHandleChangeItems = async (value) => {
  //   const { onHandleShow } = props;
  //   setItemsBeingShowed(value);
  //   onHandleShow(value, 1);
  // };

  const { currentPage, totalItems, showing, onHandlePage } = props;

  return (
    <div className="paginationComponent" ref={refContainer}>
      <Row cellSpacing={15} rowSpacing={15}>
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={12}
          verticalAlign="center"
          className="pagination-controllers"
        >
          <div className="pagination-controllers-blocks">
            {/* <label className="pagination-controllers-label" htmlFor="txtShow">Show</label>
              <select
                name="showedItems"
                id="txtShowedItems"
                value={itemsBeingShowed}
                onChange={({ target }) => onHandleChangeItems(Number(target.value))}
              >
                <option value="8">8</option>
                <option value="16">16</option>
                <option value="24">24</option>
                <option value="32">32</option>
              </select> */}
          </div>

          <div className="pagination-controllers-blocks">
            <span className="pagination-controllers-label">Exibindo</span>
            <span className="pagination-controllers-value">
              {`${(currentPage - 1) * itemsBeingShowed + 1} - ${
                (currentPage - 1) * itemsBeingShowed + showing
              }`}
            </span>
          </div>

          <div className="pagination-controllers-blocks">
            <span className="pagination-controllers-label">Total de</span>
            <span className="pagination-controllers-value">{totalItems}</span>
          </div>
        </Col>

        <Col lg={6} md={6} sm={6} xs={12} horizontalAlign="flex-end">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={itemsBeingShowed}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={(page) => onHandlePage(itemsBeingShowed, page)}
          />
        </Col>
      </Row>
    </div>
  );
};

Paginator.propTypes = {
  onHandlePage: PropTypes.func,
  onHandleShow: PropTypes.func,
  currentPage: PropTypes.number,
  showing: PropTypes.number,
  totalItems: PropTypes.number,
  totalPages: PropTypes.number,
  itemsBeingShowed: PropTypes.number,
};

Paginator.defaultProps = {
  onHandlePage: () => {},
  onHandleShow: () => {},
  currentPage: 1,
  itemsBeingShowed: 1,
  totalItems: null,
  totalPages: null,
  showing: null,
};

export default Paginator;
