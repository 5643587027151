import React, { useContext } from "react";
import { Row, Col } from "../../../components/Grid";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import RegisterContext from "../../../context/register";

const BuffetSocial = () => {
  const {
    onHandleChange,
    watch,
    buffetDetails: { facebook, youtube, instagram },
  } = useContext(RegisterContext);

  const watchInstagram = watch("instagram", instagram);
  const watchFacebook = watch("facebook", facebook);
  const watchYoutube = watch("youtube", youtube);

  return (
    <StatsBlock title="Mídias Sociais">
      <Row cellSpacing={15} rowSpacing={22}>
        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            prefix="@"
            label="Facebook"
            id="txtFacebook"
            name="facebook"
            placeholder="seu_usuario_facebook"
            autoComplete="off"
            ref={onHandleChange}
            minLength={3}
            maxLength={60}
            defaultValue={facebook}
            description={`Preview: <a href="https://facebook.com/${watchFacebook}" target="_blank">https://facebook.com/${watchFacebook}</a>`}
          />
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            prefix="@"
            label="Instagram"
            id="txtInstagram"
            name="instagram"
            placeholder="seu_usuario_instagram"
            ref={onHandleChange}
            minLength={3}
            maxLength={60}
            autoComplete="off"
            defaultValue={instagram}
            description={`Preview: <a href="https://instagram.com/${watchInstagram}" target="_blank">https://instagram.com/${watchInstagram}</a>`}
          />
        </Col>

        <Col lg={12} md={12} sm={12} xs={12}>
          <TextField
            type="text"
            prefix="@"
            label="Youtube"
            id="txtYoutube"
            name="youtube"
            placeholder="seu_usuario_youtube"
            autoComplete="off"
            ref={onHandleChange}
            minLength={3}
            maxLength={60}
            defaultValue={youtube}
            description={`Preview: <a href="https://youtube.com/channel/${watchYoutube}" target="_blank">https://youtube.com/channel/${watchYoutube}</a>`}
          />
        </Col>
      </Row>
    </StatsBlock>
  );
};

export default BuffetSocial;
