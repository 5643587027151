import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Breadcrumb from "../Breadcrumb";
import Footer from "../Footer";
import AlertMessages from "../AlertMessages";
import "./index.sass";

const MasterPage = (props) => {
  const [isHideMenu, setIsHideMenu] = useState(false);

  const onHandleToggleMenu = () => {
    isHideMenu ? setIsHideMenu(false) : setIsHideMenu(true);
  };

  const {
    title,
    children,
    breadcrumb,
    actionsComponent,
    alertMessage,
    alertOpened,
    alertType,
    onHandleAlertClose,
    settingsTitle,
    username,
  } = props;

  return (
    <div id="masterpage">
      <Helmet>
        <title>{`${title || "Home"} | ${settingsTitle}`}</title>
      </Helmet>

      <Sidebar isHideMenu={isHideMenu} />

      <main className={isHideMenu ? "main-full" : ""}>
        <Header username={username} onHandleToggleMenu={onHandleToggleMenu} />

        <Breadcrumb items={breadcrumb} />

        {title && (
          <div id="masterpage-title" className="row between no-margin">
            <div className="masterpage-heading">
              <h1>{title}</h1>
            </div>

            <div className="masterpage-actions">
              {actionsComponent && actionsComponent}
            </div>
          </div>
        )}

        <div className={title ? "wrapper" : "wrapper-no-title"}>
          <AlertMessages
            show={alertOpened}
            message={alertMessage}
            type={alertType}
            onHandleAlertClose={onHandleAlertClose}
          />

          {children}
        </div>
      </main>

      <Footer />
    </div>
  );
};

MasterPage.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  breadcrumb: PropTypes.instanceOf(Array),
  actionsComponent: PropTypes.node,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  alertOpened: PropTypes.bool,
  onHandleAlertClose: PropTypes.func,
  settingsTitle: PropTypes.string,
  username: PropTypes.string.isRequired,
  userRole: PropTypes.string,
};

MasterPage.defaultProps = {
  title: "",
  userRole: "",
  children: {},
  breadcrumb: [],
  actionsComponent: [],
  alertMessage: "",
  alertType: "",
  alertOpened: false,
  onHandleAlertClose: () => {},
  settingsTitle: "KidsBuffets",
};

export default MasterPage;
