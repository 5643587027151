import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Rating from "react-rating";
import Lightbox from "../../components/Lightbox";
import { getStatusName } from "../../helpers";

const CommentsLightbox = ({
  show,
  commentDetail,
  loading,
  onHandleUpdateCommentStatus,
  onHandleOpenCommentsLightbox,
}) => {
  return (
    <Lightbox
      show={show}
      className="mycomments-lightboxConfirmation"
      width={680}
      height={500}
      onClose={() => onHandleOpenCommentsLightbox(false)}
      title="Detalhes do comentário"
    >
      {commentDetail && Object.entries(commentDetail).length > 1 && (
        <table className="mycomments-table vertical-table">
          <tbody>
            <tr>
              <td width="90">
                <strong>Data</strong>
              </td>
              <td>
                {moment(new Date(commentDetail.created_at)).format(
                  "DD/MM/YYYY - HH:MM"
                )}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Nome</strong>
              </td>
              <td>{commentDetail.name}</td>
            </tr>
            <tr>
              <td>
                <strong>E-mail</strong>
              </td>
              <td>{commentDetail.email}</td>
            </tr>
            <tr>
              <td>
                <strong>Status</strong>
              </td>
              <td>
                <i className={getStatusName(commentDetail.status).icon} />
                {getStatusName(commentDetail.status).label}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Avaliação</strong>
              </td>
              <td>
                <Rating
                  emptySymbol={<i className="icon-star-o" />}
                  fullSymbol={<i className="icon-star" />}
                  initialRating={commentDetail.rating}
                  readonly
                />
              </td>
            </tr>
            <tr>
              <td valign="top">
                <strong>Mensagem</strong>
              </td>
              <td>{commentDetail.message}</td>
            </tr>
          </tbody>
        </table>
      )}

      {commentDetail.status && commentDetail.status === "in review" && (
        <div className="lightbox-actions">
          <button
            type="button"
            className="btn small red-theme"
            onClick={() => onHandleUpdateCommentStatus("declined")}
            disabled={loading}
          >
            Reprovar
          </button>

          <button
            type="button"
            className="btn small green-theme"
            onClick={() => onHandleUpdateCommentStatus("approved")}
            disabled={loading}
          >
            Aprovar
          </button>
        </div>
      )}
    </Lightbox>
  );
};

CommentsLightbox.propTypes = {
  show: PropTypes.bool.isRequired,
  commentDetail: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  onHandleUpdateCommentStatus: PropTypes.func.isRequired,
  onHandleOpenCommentsLightbox: PropTypes.func.isRequired,
};

export default CommentsLightbox;
