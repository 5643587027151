import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const StatsBlock = (props) => {
  const {
    total,
    label,
    title,
    icon,
    className,
    children,
    ...rest
  } = props;

  if (children && Object.entries(children).length > 0) {
    return (
      <div className={`statsBlock--children ${className}`} {...rest}>
        {
          title
          && <h2>{title}</h2>
        }

        <div className="statsBlock--wrapper">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className={`statsBlock ${className}`} {...rest}>
      <div>
        <span className="statsBlock-total">{total || 0}</span>
        <span className="statsBlock-label">{label || 'Untitled description'}</span>
      </div>

      <i className={icon} />
    </div>
  );
};

StatsBlock.propTypes = {
  total: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};

StatsBlock.defaultProps = {
  total: 0,
  label: '',
  title: '',
  icon: 'icon-cube',
  className: '',
  children: {},
};

export default StatsBlock;
