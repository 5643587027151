import React, { useState, useContext } from "react";
// import moment from 'moment';
import { Link } from "react-router-dom";
import MasterPage from "../../components/MasterPage";
import AlertMessages from "../../components/AlertMessages";
import Loader from "../../components/Loader";
import Lightbox from "../../components/Lightbox";
import FlipSwitch from "../../components/FlipSwitch";
import { REACT_APP_SITE_URL } from "../../config/variables";
import { activationStatus } from "../../actions/buffets";
import GlobalContext from "../../context/global";
import "./index.sass";

const MyBuffets = ({ history }) => {
  const {
    username,
    token,
    loadingBusinesses,
    updateBusinessesStatus,
    businesses,
  } = useContext(GlobalContext);
  const [lightboxLoading, setLightboxLoading] = useState(false);
  const [lightboxOpened, setLightboxOpened] = useState(false);
  const [lightboxId, setLightboxId] = useState(null);
  const [lightboxValue, setLightboxValue] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusMessageError, setStatusMessageError] = useState(null);

  const onHandleOpenBuffetStatusLightbox = (_bool, value = "", id = "") => {
    if (_bool) {
      setStatusMessage(null);
      setStatusMessageError(null);

      if (history.location.state) {
        history.replace({ state: null });
      }
    }

    setLightboxId(id);
    setLightboxValue(value);
    setLightboxOpened(_bool);
  };

  const onHandleChangeActivationStatus = async () => {
    setLightboxLoading(true);

    try {
      const { message } = await activationStatus(
        token,
        lightboxValue,
        lightboxId
      );
      updateBusinessesStatus(lightboxId, lightboxValue);
      setStatusMessage(message);
    } catch (err) {
      setStatusMessageError(err);
    }

    setLightboxLoading(false);
    resetLightboxValues();
  };

  const resetLightboxValues = () => {
    setLightboxId(null);
    setLightboxValue(null);
    setLightboxOpened(false);
  };

  return (
    <MasterPage
      title="Meus Anúncios"
      username={username}
      breadcrumb={[{ name: "Detalhes" }]}
      actionsComponent={
        <Link to="/meus-anuncios/cadastrar" className="btn small blue-theme">
          Cadastrar novo anúncio
        </Link>
      }
    >
      <AlertMessages
        show={!!statusMessageError}
        type="error"
        message={statusMessageError}
      />

      <AlertMessages
        show={!!statusMessage}
        type="success"
        message={statusMessage}
      />

      <AlertMessages
        show={!!history.location.state && !!history.location.state.message}
        type="success"
        message={
          history.location.state && history.location.state.message
            ? history.location.state.message
            : ""
        }
      />

      {loadingBusinesses ? (
        <Loader />
      ) : (
        <>
          <table className="mybuffets-table">
            <thead>
              <tr>
                <th>Nome</th>
                {/* <th>Plano</th> */}
                <th>Ativo/Inativo</th>
                <th>Visualizações</th>
                <th>Comentários</th>
                <th>Orçamentos</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {businesses.length > 0 ? (
                businesses.map((item) => (
                  <tr key={item.id}>
                    <td data-label="Nome">{item.name}</td>
                    {/* <td data-label="Plano">{item.plan}</td> */}
                    <td data-label="Ativo" valign="center">
                      <FlipSwitch
                        id={item.id}
                        value={item.isActive}
                        onChange={(value) =>
                          onHandleOpenBuffetStatusLightbox(true, value, item.id)
                        }
                        disabled={item.isCompleted === false}
                      />
                    </td>
                    <td data-label="Visualizações">{item.total_views}</td>
                    <td data-label="Comentários">{item.comments}</td>
                    <td data-label="Orçamentos">{item.budgets}</td>
                    <td data-label="Ações">
                      {item.isCompleted ? (
                        <Link
                          to={`/meus-anuncios/editar/${item.id}`}
                          className="btn mini blue-theme"
                        >
                          Editar
                        </Link>
                      ) : (
                        <Link
                          to={`/meus-anuncios/editar/${item.id}`}
                          className="btn mini orange-theme"
                        >
                          Completar Anúncio
                        </Link>
                      )}

                      {item.isActive && (
                        <a
                          href={`${REACT_APP_SITE_URL}/buffet-infantil/${item.slug}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn mini purple-theme"
                        >
                          Ver Anúncio
                        </a>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td align="center" colSpan="6">
                    Nenhum anúncio cadastrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Lightbox
            show={lightboxOpened}
            className="mybuffets-lightboxConfirmation"
            width={680}
            height={230}
            onClose={() => onHandleOpenBuffetStatusLightbox(false)}
            title="Você tem certeza?"
          >
            {lightboxValue === false && (
              <p className="mybuffets-lightboxConfirmation-desc">
                Ao confirmar, seu buffet não será mais visível para os usuários.
              </p>
            )}

            {lightboxValue === true && (
              <p className="mybuffets-lightboxConfirmation-desc">
                Ao confirmar, seu buffet estará público para todos os usuários.
              </p>
            )}

            <div className="lightbox-actions">
              <button
                type="button"
                className="btn small"
                onClick={() => onHandleChangeActivationStatus()}
                disabled={lightboxLoading}
              >
                {lightboxLoading ? "Carregando..." : "Confirmar"}
              </button>

              <button
                type="button"
                className="btn small no-theme"
                onClick={() => onHandleOpenBuffetStatusLightbox(false)}
                disabled={lightboxLoading}
              >
                Cancelar
              </button>
            </div>
          </Lightbox>
        </>
      )}
    </MasterPage>
  );
};

export default MyBuffets;
