/* eslint-disable no-undef */
import React from "react";
import uuidv1 from "uuid/v1";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import myRoutes from "./routers";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Page404 from "../containers/404";

const Routers = () => (
  <BrowserRouter>
    <Switch>
      {myRoutes
        .filter((route) => route.active)
        .map((item) => {
          if (item.private) {
            return (
              <PrivateRoute
                key={uuidv1()}
                exact
                path={item.path}
                context={item.context}
                component={item.component}
                permission={item.permission}
                routerProps={item.routerProps}
              />
            );
          }

          return (
            <PublicRoute
              key={uuidv1()}
              exact
              path={item.path}
              component={item.component}
              permission={item.permission}
              routerProps={item.routerProps}
            />
          );
        })}

      <Route component={() => <Page404 auth={false} />} />
    </Switch>
  </BrowserRouter>
);

export default Routers;
