import React, { useState, useContext, useEffect } from "react";
// import queryString from "query-string";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "../../assets/images/kidsbuffet-logo.svg";
import TextField from "../../components/TextField";
import Loader from "../../components/Loader";
import AlertMessages from "../../components/AlertMessages";
import { authenticate } from "../../actions/auth";
import GlobalContext from "../../context/global";
import { detectIE } from "../../helpers/";
import "./index.sass";

const Login = ({ location }) => {
  const { addItemLocalStorage } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    checkBrowser();
  }, []);

  const checkBrowser = () => {
    // Get IE or Edge 80- browser version
    const version = detectIE();

    if (version === "IE") {
      window.location.href = "/navegador-nao-suportado";
    } else {
      setLoading(false);
    }
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    setSubmitLoading(true);

    try {
      const { data } = await authenticate({ username, password });
      addItemLocalStorage(data);
    } catch (err) {
      setError(err);
      setSubmitLoading(false);
    }
  };

  // const checkQueryStringToRedirect =
  //   location.search && queryString.parse(location.search).from
  //     ? queryString.parse(location.search).from
  //     : null;

  // console.log(222, token, checkQueryStringToRedirect);

  // if (token) {
  //   debugger;
  //   if (checkQueryStringToRedirect) {
  //     debugger;
  //     console.log(111, `/${queryString.parse(location.search).from}`);
  //     return <Redirect to={`${queryString.parse(location.search).from}`} />;
  //   } else if (location.pathname && location.pathname !== "/") {
  //     debugger;
  //     return <Redirect to={`${location.pathname}`} />;
  //   } else {
  //     debugger;
  //     return <Redirect to="/meus-anuncios" />;
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>Login | KidsBuffets Admin</title>
        <meta
          name="description"
          content="Plataforma exclusiva para anunciantes de buffets infantís - Acompanhamentos, relatórios, criação e edição de anúncios"
        />
      </Helmet>

      {loading && <Loader full />}

      <div id="login">
        <div id="box">
          <form onSubmit={onHandleSubmit}>
            <img src={logo} alt="logo" />

            <TextField
              type="text"
              name="username"
              id="username"
              iconClass="icon-user"
              onChange={({ target }) => setUsername(target.value)}
              value={username}
              placeholder="email"
              required
              minLength={3}
            />

            <TextField
              type="password"
              name="password"
              id="password"
              iconClass="icon-lock"
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              placeholder="senha"
              required
              minLength={8}
            />

            <div className="login-forgotPassword">
              <Link to="/forgot-password">Esqueceu sua senha?</Link>
            </div>

            <AlertMessages
              show={!!error}
              message={error}
              type="error"
              className="login-error"
            />

            <div id="login-actions">
              <button
                type="submit"
                className="btn blue-theme"
                disabled={submitLoading}
              >
                {submitLoading ? "Carregando..." : "Entrar"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

Login.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default Login;
