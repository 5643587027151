import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Login from "../../containers/Login";
import Page404 from "../../containers/404";
import GlobalContext from "../../context/global";

const PrivateRoute = ({
  context: Context,
  component: Component,
  permission,
  routerProps,
  ...rest
}) => {
  const { token, userRole } = useContext(GlobalContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          if (permission.includes(userRole)) {
            if (Context) {
              return (
                <Context>
                  <Component
                    auth
                    routerPermission={permission}
                    routerProps={routerProps}
                    {...props}
                  />
                </Context>
              );
            }

            return (
              <Component
                auth
                routerPermission={permission}
                routerProps={routerProps}
                {...props}
              />
            );
          }

          return <Page404 />;
        }

        return (
          <Login
            auth={false}
            routerPermission={permission}
            routerProps={routerProps}
            {...props}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  permission: PropTypes.instanceOf(Array).isRequired,
  routerProps: PropTypes.instanceOf(Object),
};

PrivateRoute.defaultProps = {
  routerProps: {},
};

export default PrivateRoute;
