export const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_ORIGIN,
  REACT_APP_CLOUDINARY_UPLOADS,
  REACT_APP_SITE_URL,
} = process.env;

export const env = {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_ORIGIN,
  REACT_APP_CLOUDINARY_UPLOADS,
  REACT_APP_SITE_URL,
};
