import React from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { Row, Col } from "../../components/Grid";
import logo from "../../assets/images/kidsbuffet-logo.svg";
import "./index.sass";

const IE = () => {
  return (
    <>
      <Helmet>
        <title>OPS! | KidsBuffets Admin</title>
        <meta
          name="description"
          content="Plataforma exclusiva para anunciantes de buffets infantís - Acompanhamentos, relatórios, criação e edição de anúncios"
        />
      </Helmet>

      <div className="IE">
        <div className="IE-content">
          <Row cellSpacing={0} rowSpacing={35}>
            <Col lg={12} md={12} sm={12} xs={12} horizontalAlign="center">
              <Link to="/">
                <img src={logo} alt="KidsBuffets" />
              </Link>
            </Col>
          </Row>

          <Row cellSpacing={40} rowSpacing={15} verticalAlign="center">
            <Col lg={12} md={12} sm={12} xs={12} verticalAlign="center">
              <h1 className="IE-title">
                OOOPS... A versão do seu navegador não é suportada!
              </h1>
              <p className="IE-description">
                A versão atual do seu navegador é muito antiga e compromete
                algumas funcionalidades do sistema. Por favor atualize o seu
                navegador para a versão mais recente disponível e mantenha sua
                navegação em segurança.
              </p>

              <div className="IE-btn">
                <a
                  className="btn blue-theme"
                  href="https://www.microsoft.com/pt-br/edge"
                >
                  Atualizar meu navegador agora
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default IE;
