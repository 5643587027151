import React, { forwardRef, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { REACT_APP_CLOUDINARY_UPLOADS } from "../../config/variables";
import "./index.sass";

const InputFile = forwardRef((props, ref) => {
  const refContainer = useRef();
  const [fileImageRemoved, setFileImageRemoved] = useState(false);
  const [inputName, setInputName] = useState("");
  const [inputUrl, setInputUrl] = useState("");
  const [inputError, setInputError] = useState(null);
  const [fileImage, setFileImage] = useState(props.fileimage);

  useEffect(() => {
    loadPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPreview = () => {
    if (fileImage) {
      setInputName(fileImage.split("/").pop());
      setInputUrl(`${REACT_APP_CLOUDINARY_UPLOADS}/${fileImage}`);
      setInputError(null);
    }
  };

  const onHandleChange = (e) => {
    const file = e.target.files[0];
    const { maxFileSize } = props;

    if (file) {
      if (file.size < maxFileSize) {
        getPreview(file);
      } else {
        setInputName("");
        setInputUrl("");
        setInputError("OPS! Seu arquivo é muito grande. Tamanho máximo: 1MB");
        if (refContainer.current) refContainer.current.value = "";
      }
    } else {
      setInputName("");
      setInputUrl("");
      setInputError(null);
    }
  };

  const onHandleRemove = (fileURL) => {
    const { onRemove } = props;

    setFileImageRemoved(!!fileImage);
    setInputName("");
    setInputUrl("");
    setInputError(null);
    setFileImage(null);

    if (refContainer.current) refContainer.current.value = "";

    if (fileURL) {
      onRemove(fileURL);
    }
  };

  const getPreview = (file) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    reader.onloadend = () => {
      setInputName(file.name);
      setInputUrl(reader.result);
      setInputError(null);
    };

    reader.readAsDataURL(file);
  };

  const { accept, defaultValue, name, id, required } = props;

  const filename = inputName;

  const currentValue = refContainer.current ? refContainer.current.value : null;

  return (
    <div className="inputFile">
      <div className="inputFile-input">
        {(!currentValue && !fileImage) ||
        (!currentValue && fileImage && fileImageRemoved) ? (
          <span className="inputFile-placeholder">Selecione um arquivo...</span>
        ) : (
          <div className="inputFile-content">
            <figure className="inputFile-content-figure">
              <img src={inputUrl || currentValue} alt="" />
            </figure>

            <div className="inputFile-content-info">
              <span className="inputFile-content-info-name">
                {filename && filename.length > 40
                  ? `${filename.substring(0, 40)}...`
                  : filename}
              </span>

              {!inputError && (inputUrl || currentValue) && (
                <div className="inputFile-content-info-actions">
                  {(!fileImage || (fileImage && fileImageRemoved)) && (
                    <label htmlFor={id} className="inputFile-update">
                      Alterar Imagem
                    </label>
                  )}

                  <span
                    className="inputFile-remove icon-cancel"
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => onHandleRemove(fileImage || null)}
                    onClick={() => onHandleRemove(fileImage || null)}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {fileImage && !fileImageRemoved ? (
          <>
            <input type="hidden" value={filename} name={name} />
          </>
        ) : (
          <input
            type="file"
            id={id}
            name={name}
            onChange={onHandleChange}
            accept={accept}
            defaultValue={defaultValue}
            ref={(e) => {
              ref(e, {
                required: required,
              });
              refContainer.current = e;
            }}
          />
        )}
      </div>

      {inputError && <span className="inputFile-error">{inputError}</span>}
    </div>
  );
});

InputFile.propTypes = {
  onRemove: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  required: PropTypes.bool,
  maxFileSize: PropTypes.number,
  fileimage: PropTypes.string,
};

InputFile.defaultProps = {
  fileimage: "",
  accept: ".png, .jpg",
  required: false,
  maxFileSize: 1000000, // 1MB
};

export default InputFile;
