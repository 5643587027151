import { APIResquest } from "../utils/api";
import { REACT_APP_ORIGIN } from "../config/variables";

export function authenticate(data) {
  return APIResquest({
    uri: "auth",
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    data,
  });
}

export function forgotPassword(username) {
  return APIResquest({
    uri: "forgotPassword",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      username,
    },
  });
}

export function recoverPassword(data) {
  return APIResquest({
    uri: "updatePassword",
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    data,
  });
}
