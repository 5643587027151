import React, { useContext } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { withRouter, Link, NavLink } from "react-router-dom";
import ReactLogo from "../../assets/images/kidsbuffet-logo-white.png";
import Loader from "../Loader";
import GlobalContext from "../../context/global";
import styles from "./index.module.sass";

const Sidebar = (props) => {
  const { businesses, userRole } = useContext(GlobalContext);
  const { location, isHideMenu, title, logo } = props;

  let currentBusinesses = null;

  if (businesses[0]) {
    if (
      queryString.parse(location.search) &&
      queryString.parse(location.search).businessId
    ) {
      currentBusinesses = queryString.parse(location.search).businessId;
    } else {
      currentBusinesses = businesses[0].id;
    }
  }

  return (
    <aside className={`${styles.sidebar} ${isHideMenu ? styles.closed : ""}`}>
      <div className={styles.header}>
        <Link to="/" className={styles.headerLink}>
          <img
            src={logo && logo.src ? logo.src : ReactLogo}
            alt="logo"
            className={styles.headerLogo}
          />
          {title}
        </Link>
      </div>

      {currentBusinesses ? (
        <nav className={styles.nav}>
          <dl className={styles.navList}>
            <dt className={styles.navTitle}>Navegação</dt>
            <dd className={styles.navItem}>
              <NavLink
                to="/meus-anuncios"
                activeClassName={styles.navActive}
                className={styles.navLink}
              >
                <i className={`${styles.navIcon} icon-home`} />
                Meus anúncios
              </NavLink>
            </dd>

            <dd className={styles.navItem}>
              <NavLink
                to={`/comentarios?businessId=${currentBusinesses}`}
                activeClassName={styles.navActive}
                className={styles.navLink}
              >
                <i className={`${styles.navIcon} icon-commenting`} />
                Gerenciar Comentários
              </NavLink>
            </dd>

            <dd className={styles.navItem}>
              <NavLink
                to={`/orcamentos?businessId=${currentBusinesses}`}
                activeClassName={styles.navActive}
                className={styles.navLink}
              >
                <i className={`${styles.navIcon} icon-briefcase`} />
                Gerenciar Orçamentos
              </NavLink>
            </dd>
          </dl>

          {userRole === "Super Admin" && (
            <>
              <dl className={styles.navList}>
                <dt className={styles.navTitle}>Marketing</dt>
                <dd className={styles.navItem}>
                  <NavLink
                    to="/buffetsNotRegistered"
                    activeClassName={styles.navActive}
                    className={styles.navLink}
                  >
                    <i className={`${styles.navIcon} icon-briefcase`} />
                    Buffets não registrados
                  </NavLink>
                </dd>
              </dl>

              <dl className={styles.navList}>
                <dt className={styles.navTitle}>Administração</dt>
                <dd className={styles.navItem}>
                  <NavLink
                    to="/all-buffets"
                    activeClassName={styles.navActive}
                    className={styles.navLink}
                  >
                    <i className={`${styles.navIcon} icon-briefcase`} />
                    Buffets registrados
                  </NavLink>
                </dd>
              </dl>
            </>
          )}
        </nav>
      ) : (
        <Loader />
      )}
    </aside>
  );
};

Sidebar.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  isHideMenu: PropTypes.bool.isRequired,
  title: PropTypes.string,
  logo: PropTypes.string,
};

Sidebar.defaultProps = {
  title: "",
  logo: "",
};

export default withRouter(Sidebar);
