import { APIResquest } from "../utils/api";
import { REACT_APP_ORIGIN } from "../config/variables";

export function updateCommentStatus(token, buffetId, commentId, value) {
  return APIResquest({
    uri: "updateCommentStatus",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
      commentId,
      commentStatus: value,
    },
  });
}

export function commentsByBuffetId(token, buffetId, limit = 10, page = 1) {
  return APIResquest({
    uri: "comments",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
      limit,
      page,
    },
  });
}
