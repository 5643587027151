import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import queryString from "query-string";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "../../assets/images/kidsbuffet-logo.svg";
import TextField from "../../components/TextField";
import { recoverPassword } from "../../actions/auth";
import AlertMessages from "../../components/AlertMessages";
import Loader from "../../components/Loader";
import "./index.sass";

const RecoverPassword = ({ location }) => {
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidToken, setInvalidToken] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    checkToken();
  });

  const checkToken = () => {
    const getToken = location.search
      ? queryString.parse(location.search).token
      : null;

    if (!getToken) {
      setInvalidToken(true);
    }

    setLoading(false);
  };

  const clearMessages = () => {
    setError(null);
    setSuccess(null);
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    clearMessages();

    const { token } = queryString.parse(location.search);

    setSubmitLoading(true);

    try {
      const { message } = await recoverPassword({ password, token });
      setSuccess(message);
    } catch (err) {
      setError(err);
    }

    setSubmitLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Recuperar senha | KidsBuffets Admin</title>
      </Helmet>

      <div className="recoverPassword">
        <div id="box">
          <form onSubmit={(e) => onHandleSubmit(e)}>
            <img src={logo} alt="logo" className="recoverPassword-logo" />

            {loading ? (
              <div className="recoverPassword-loading">
                <Loader />
              </div>
            ) : (
              <>
                {invalidToken && (
                  <AlertMessages
                    show={invalidToken}
                    message="Token inválido!"
                    type="error"
                    className="recoverPassword-alertMessage"
                  />
                )}

                <AlertMessages
                  show={!!success}
                  message={success}
                  type="success"
                  className="recoverPassword-alertMessage"
                />

                <AlertMessages
                  show={!!error}
                  message={error}
                  type="error"
                  className="recoverPassword-alertMessage"
                />

                {!!success && (
                  <div className="recoverPassword-link">
                    <Link to="/">Retornar ao login</Link>
                  </div>
                )}

                {!error && !success && !invalidToken && (
                  <>
                    <p className="recoverPassword-desc">
                      Preencha os campos abaixo para alterar sua senha. Sua
                      senha deve conter no mínimo 8 caracteres.
                    </p>

                    <TextField
                      type="password"
                      name="password"
                      iconClass="icon-lock"
                      onChange={({ target }) => setPassword(target.value)}
                      value={password}
                      autoComplete="new-password"
                      placeholder="senha"
                      disabled={submitLoading}
                      required
                      minLength={8}
                    />

                    <TextField
                      type="password"
                      name="confirmPassword"
                      iconClass="icon-lock"
                      onChange={({ target }) =>
                        setConfirmPassword(target.value)
                      }
                      value={confirmPassword}
                      autoComplete="new-password"
                      placeholder="confirmar senha"
                      disabled={submitLoading}
                      pattern={confirmPassword === password ? null : "^(true)$"}
                      required
                      minLength={8}
                    />

                    <div id="recoverPassword-actions">
                      <button
                        type="submit"
                        className="btn blue-theme"
                        disabled={submitLoading}
                      >
                        {submitLoading ? "Carregando..." : "Alterar senha"}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

RecoverPassword.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default RecoverPassword;
