export const validEmailAddress = (email) => {
  const regex = /^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/;

  return {
    regex,
    regexAsString: regex.source,
    result: regex.test(email),
  };
};

export const validPasswordStraight = (password) => {
  const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  return {
    regex,
    regexAsString: regex.source,
    lowercase: /(?=.*?[a-z])/.test(password),
    uppercase: /(?=.*?[A-Z])/.test(password),
    number: /(?=.*?[0-9])/.test(password),
    specialChar: /(?=.*?[#?!@$%^&*-])/.test(password),
    length: password.length >= 8,
    result: regex.test(password),
  };
};

export const validWebsite = (website) => {
  const regex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  return {
    regex,
    regexAsString: regex.source,
    result: regex.test(website),
  };
};

export const validCNPJ = (cnpj) => {
  const regex = /\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}/;

  return {
    regex,
    regexAsString: regex.source,
    result: regex.test(cnpj),
  };
};

export const validBusinessName = (name) => {
  const regex = /^(?!buffet)/gi;

  return {
    regex,
    regexAsString: regex.source,
    result: regex.test(name),
  };
};

export const normalizeString = (_str) => {
  if (typeof _str !== "undefined") {
    return _str
      .trim()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/\s\s+/, " ")
      .toLowerCase();
  }

  return false;
};

export const orderBy = (arr = [], propName, order = "asc") =>
  arr &&
  arr.length > 0 &&
  arr.slice(0).sort((a, b) => {
    const varA =
      typeof a[propName] === "string"
        ? normalizeString(a[propName])
        : a[propName];
    const varB =
      typeof b[propName] === "string"
        ? normalizeString(b[propName])
        : b[propName];
    let orderDesc = 1;
    let orderAsc = -1;

    if (order === "desc") {
      orderDesc = -1;
      orderAsc = 1;
    }

    if (varA > varB) return orderDesc;
    if (varA < varB) return orderAsc;
    return 0;
  });

export const isNumber = (_string) => /^[0-9]*$/.test(_string);

export const getStatusName = (status) => {
  switch (status) {
    case "in review": {
      return {
        name: "in-review",
        label: "Em revisão",
        icon: "icon-circle-in-review",
      };
    }

    case "approved": {
      return {
        name: "approved",
        label: "Aprovado",
        icon: "icon-circle-approved",
      };
    }

    case "declined": {
      return {
        name: "declined",
        label: "Reprovado",
        icon: "icon-circle-declined",
      };
    }

    default: {
      return {
        name: "declined",
        label: "Reprovado",
        icon: "icon-circle-declined",
      };
    }
  }
};

export const detectIE = () => {
  const ua = window.navigator.userAgent;
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  // Edge 80
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edg/0';

  const msie = ua.indexOf("MSIE ");
  const trident = ua.indexOf("Trident/");

  if (msie > 0 || trident > 0) {
    return "IE";
  }

  const edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    // return `EDGE ${parseInt(
    //   ua.substring(edge + 5, ua.indexOf(".", edge)),
    //   10
    // )}`;
    return "IE";
  }

  // other browser
  return "NOT IE";
};

const Helpers = {
  validEmailAddress,
  validPasswordStraight,
  validWebsite,
  isNumber,
  orderBy,
  normalizeString,
  getStatusName,
  validBusinessName,
  detectIE,
};

export default Helpers;
