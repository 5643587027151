/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import queryString from "query-string";
import Rating from "react-rating";
import Loader from "../../components/Loader";
import MasterPage from "../../components/MasterPage";
import { Row, Col } from "../../components/Grid";
import AlertMessages from "../../components/AlertMessages";
import CommentsLightbox from "./lightbox";
import Paginator from "../../components/Paginator";
import StatsBlock from "../../components/StatsBlock";
import TextField from "../../components/TextField";
import {
  commentsByBuffetId,
  updateCommentStatus,
} from "../../actions/comments";
import { getStatusName } from "../../helpers";
import GlobalContext from "../../context/global";
import {
  useActiveCurrentBusinesses,
  useUpdatePageHistory,
} from "../../hooks/usePage";
import "./index.sass";

const MyComments = ({ location, history }) => {
  const { token, username, loading, businesses, businessesError } = useContext(
    GlobalContext
  );

  const [
    activeCurrentBusinesses,
    setActiveCurrentBusinesses,
  ] = useActiveCurrentBusinesses(history, location);

  const [setUpdatePageHistory] = useUpdatePageHistory(history, location);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsPage, setTotalItemsPage] = useState(1);

  const [commentsLoading, setCommentsLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [commentStatusSuccess, setCommentStatusSuccess] = useState(null);
  const [commentStatusError, setCommentStatusError] = useState(null);

  const [lightboxStatusOpened, setLightboxStatusOpened] = useState(false);
  const [lightboxStatusObj, setLightboxStatusObj] = useState({});
  const [lightboxStatusLoading, setLightboxStatusLoading] = useState(false);

  const limit = 10;

  useEffect(() => {
    loadBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businesses]);

  const resetLightboxState = () => {
    setLightboxStatusOpened(false);
    setLightboxStatusObj({});
    setLightboxStatusLoading(false);
  };

  const loadBusiness = () => {
    const getBusinessId = location.search
      ? queryString.parse(location.search).businessId
      : null;
    const findBusinesses = getBusinessId
      ? businesses.find((item) => item.id === getBusinessId)
      : null;
    const currentBusinesses = findBusinesses ? findBusinesses : businesses[0];

    if (businesses.length > 0) {
      loadComments(currentBusinesses);
    }
  };

  const fetchComments = async (
    pageToShow,
    currentBusinesses = businesses[0]
  ) => {
    setCommentsLoading(true);

    const getPageParams =
      location.search && queryString.parse(location.search).page
        ? Number(queryString.parse(location.search).page)
        : currentPage;

    try {
      const pageToSubmit = pageToShow || getPageParams;
      const result = await commentsByBuffetId(
        token,
        currentBusinesses.id,
        limit,
        pageToSubmit
      );
      setTotalItemsPage(result.totalItems);
      setCurrentPage(result.currentPage);
      setComments(result.data);
    } catch (err) {}

    setCommentsLoading(false);
  };

  const loadComments = (currentBusinesses = businesses[0], _page = null) => {
    if (currentBusinesses.comments > 0) {
      fetchComments(_page, currentBusinesses);
    } else {
      setComments([]);
      setCommentsLoading(false);
    }

    setActiveCurrentBusinesses(currentBusinesses);
  };

  const onHandleChangeBusinesses = (businessesId) => {
    clearMessages();

    const findBusinesses = businesses.find((item) => item.id === businessesId);

    if (findBusinesses) {
      loadComments(findBusinesses, 1);
    }
  };

  const onHandleChangePage = (itemsBeingShowed, _page) => {
    clearMessages();

    setUpdatePageHistory(_page);

    fetchComments(_page, activeCurrentBusinesses);
  };

  const onHandleUpdateCommentStatus = async (status) => {
    setLightboxStatusLoading(true);

    try {
      const { message } = await updateCommentStatus(
        token,
        lightboxStatusObj.buffetId,
        lightboxStatusObj._id,
        status
      );

      const commentsNewValue = comments.map((item) => {
        if (item._id === lightboxStatusObj._id) {
          return {
            ...item,
            status: status,
          };
        }

        return item;
      });

      setComments(commentsNewValue);
      setCommentStatusSuccess(message);
    } catch (err) {
      setCommentStatusError(err);
    }

    resetLightboxState();
  };

  const onHandleOpenCommentsLightbox = (_bool, obj) => {
    if (_bool) {
      clearMessages();
    }

    setLightboxStatusOpened(_bool);
    setLightboxStatusObj(obj || {});
  };

  const clearMessages = () => {
    setCommentStatusSuccess(null);
    setCommentStatusError(null);
  };

  return (
    <MasterPage
      title="Gerenciar Comentários"
      username={username}
      breadcrumb={[{ name: "Gerenciar Comentários" }]}
    >
      <AlertMessages
        show={!!commentStatusSuccess}
        type="success"
        message={commentStatusSuccess}
      />

      <AlertMessages
        show={!!commentStatusError}
        type="error"
        message={commentStatusError}
      />

      <AlertMessages
        show={!!businessesError}
        type="error"
        message={businessesError}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {activeCurrentBusinesses && businesses.length > 0 && (
            <StatsBlock title="Pesquisar por" style={{ marginBottom: 30 }}>
              <Row cellSpacing={15} rowSpacing={0}>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    type="select"
                    label="Nome"
                    id="businesses"
                    name="businesses"
                    onChange={({ target }) =>
                      onHandleChangeBusinesses(target.value)
                    }
                    value={activeCurrentBusinesses.id}
                  >
                    {businesses.map((item) => (
                      <option key={item.id} value={item.id}>
                        Buffet Infantil {item.name}
                      </option>
                    ))}
                  </TextField>
                </Col>

                {/* <Col lg={3} md={6} sm={6} xs={12}>
                  <TextField
                    type="select"
                    label="Status"
                    id="businessesStatus"
                    name="businessesStatus"
                    onChange={({ target }) => {}}
                    value={businesses[0].id}
                  >
                    <option value="all">Todos</option>
                    <option value="approved">Aprovado</option>
                    <option value="inReview">Em revisão</option>
                    <option value="declined">Reprovado</option>
                  </TextField>
                </Col> */}
              </Row>
            </StatsBlock>
          )}

          {commentsLoading ? (
            <Loader />
          ) : (
            <table className="mycomments-table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th width="200">E-mail</th>
                  <th>Status</th>
                  <th>Avaliação</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {comments.length > 0 ? (
                  <>
                    {comments.map((item) => (
                      <tr key={item._id}>
                        <td data-label="Data" valign="top">
                          {moment(new Date(item.created_at)).format(
                            "DD/MM/YYYY - HH:MM"
                          )}
                        </td>
                        <td data-label="E-mail" valign="top">
                          {item.email}
                        </td>
                        <td data-label="Status" valign="top">
                          <i className={getStatusName(item.status).icon} />
                          {getStatusName(item.status).label}
                        </td>
                        <td data-label="Avaliação" valign="top">
                          <Rating
                            emptySymbol={<i className="icon-star-o" />}
                            fullSymbol={<i className="icon-star" />}
                            initialRating={item.rating}
                            readonly
                          />
                        </td>

                        <td valign="top">
                          <button
                            type="button"
                            className="btn mini blue-theme"
                            onClick={() =>
                              onHandleOpenCommentsLightbox(true, item)
                            }
                          >
                            Detalhes
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="5" valign="top" align="center">
                      Nenhum comentário encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {comments.length > 0 && (
            <Paginator
              itemsBeingShowed={limit}
              currentPage={currentPage}
              totalItems={totalItemsPage}
              showing={comments.length}
              onHandlePage={(itemsBeingShowed, page) =>
                onHandleChangePage(itemsBeingShowed, page)
              }
            />
          )}

          <CommentsLightbox
            show={lightboxStatusOpened}
            commentDetail={lightboxStatusObj}
            loading={lightboxStatusLoading}
            onHandleUpdateCommentStatus={onHandleUpdateCommentStatus}
            onHandleOpenCommentsLightbox={onHandleOpenCommentsLightbox}
          />
        </>
      )}
    </MasterPage>
  );
};

export default MyComments;
