import React, { useEffect, useState, useContext, createContext } from "react";

import { useForm } from "react-hook-form";
import JSONDistricts from "../mocks/districts.json";
import { orderBy } from "../helpers";
import { detailsByUser } from "../actions/buffets";
import { getCEPDetails } from "../actions/cep";
import GlobalContext from "./global";

const RegisterContext = createContext();

const buffetDetailsInitial = {
  address: "",
  capacity: "",
  city: "",
  cnpj: "",
  customerService: "",
  description: "",
  district: "",
  district2: "",
  email_to_receive_budgets: "",
  facebook: "",
  ibge: "",
  instagram: "",
  isActive: false,
  name: "",
  number: "",
  phone: "",
  photos: [],
  photo01: "",
  photo02: "",
  photo03: "",
  price: 0,
  plan: "",
  state: "",
  tags: "",
  website: "",
  whatsapp: "",
  youtube: "",
  zipCode: "",
};

export const RegisterProvider = ({ children }) => {
  const { token } = useContext(GlobalContext);
  const { handleSubmit, register, errors, watch, triggerValidation } = useForm({
    validateCriteriaMode: "all",
  });

  const [loading, setLoading] = useState(true);
  const [loadingZipCode, setLoadingZipCode] = useState(false);
  const [editing, setEditing] = useState(false);
  const [listOfDistricts, setListOfDistricts] = useState([]);
  const [buffetDetails, setBuffetDetails] = useState(buffetDetailsInitial);
  const [buffetDetailsError, setBuffetDetailsError] = useState(null);

  const [photosRemoved, setPhotosRemoved] = useState([]);
  const [zipCodeIsValid, setZipCodeIsValid] = useState(false);

  useEffect(() => {
    getBuffetDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBuffetDetails = async () => {
    const { params } = children.props.match;

    if (params.buffetId) {
      if (!buffetDetails.id || buffetDetails.id !== params.buffetId) {
        try {
          const { data } = await detailsByUser(token, params.buffetId);
          setBuffetDetails({
            ...buffetDetails,
            ...data,
          });
          loadBuffetDetails(data);
        } catch (err) {
          setBuffetDetailsError(err);
          setLoading(false);
        }
      } else {
        loadBuffetDetails();
      }
    } else {
      setLoading(false);
    }
  };

  const loadBuffetDetails = async (data = buffetDetails) => {
    if (data.id) {
      const list = JSONDistricts.filter((item) =>
        item.ibge.includes(data.ibge)
      );

      setZipCodeIsValid(true);
      setListOfDistricts(list.length > 0 ? orderBy(list, "name") : []);
      setEditing(true);
    }

    setLoading(false);
  };

  const onHandleRemoveFile = (photoURL) => {
    const findPhoto = buffetDetails.photos.filter(
      (photo) => photo.url === photoURL
    );

    if (findPhoto && findPhoto.length > 0) {
      const newValue = photosRemoved.concat(findPhoto);
      setPhotosRemoved(newValue);
    }
  };

  const onHandleBlurCep = async ({ target }) => {
    const getCurrentValue = target.value;

    if (getCurrentValue.length === 8) {
      setLoadingZipCode(true);

      const getAddress = await getCEPDetails(getCurrentValue);

      if (!getAddress || getAddress.erro) {
        invalidCep();
        setLoadingZipCode(false);
      } else {
        const list = JSONDistricts.filter((item) =>
          item.ibge.includes(getAddress.ibge)
        );

        const obj = {
          ...buffetDetails,
          address: getAddress.logradouro,
          city: getAddress.localidade,
          district: getAddress.bairro,
          district2: "",
          state: getAddress.uf,
          ibge: getAddress.ibge || "",
        };

        setBuffetDetails(obj);
        setZipCodeIsValid(true);
        setListOfDistricts(list.length > 0 ? orderBy(list, "name") : []);
        setLoadingZipCode(false);
      }
    } else {
      invalidCep();
    }

    if (errors && Object.entries(errors).length > 0) {
      triggerValidation();
    }
  };

  const invalidCep = () => {
    const obj = {
      ...buffetDetails,
      address: "",
      city: "",
      district: "",
      district2: "",
      state: "",
    };

    setZipCodeIsValid(false);
    setBuffetDetails(obj);
  };

  return (
    <RegisterContext.Provider
      value={{
        loading,
        loadingZipCode,
        buffetDetails,
        buffetDetailsError,
        listOfDistricts,
        zipCodeIsValid,
        photosRemoved,
        editing,
        errors,
        watch,
        onHandleBlurCep,
        onHandleRemoveFile,
        onHandleChange: register,
        handleSubmit,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

export const RegisterConsumer = RegisterContext.Consumer;

export default RegisterContext;
