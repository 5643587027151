/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Loader from "../../../../components/Loader";
import MasterPage from "../../../../components/MasterPage";
import AlertMessages from "../../../../components/AlertMessages";
import { Row, Col } from "../../../../components/Grid";
import StatsBlock from "../../../../components/StatsBlock";
import TextField from "../../../../components/TextField";
import { validWebsite } from "../../../../helpers";
import GlobalContext from "../../../../context/global";
import {
  saveNotRegisteredBusiness,
  detailsNotRegisteredBusiness,
} from "../../../../actions/buffetsNotRegistered";

const BuffetNotRegisteredForm = ({ match }) => {
  const { token, username, userRole } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [editing, setEditing] = useState(false);
  const [buffetId] = useState(match.params.buffetId || null);

  const [buffetDetailsError, setBuffetDetailsError] = useState("");
  const [buffetSaved, setBuffetSaved] = useState("");
  const [buffetSaveError, setBuffetSaveError] = useState("");

  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [website, setWebsite] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  useEffect(() => {
    getBuffetDetails();
    // eslint-disable-next-line
  }, [buffetId]);

  const getBuffetDetails = async () => {
    if (buffetId) {
      setLoading(true);

      try {
        const { data } = await detailsNotRegisteredBusiness(
          token,
          match.params.buffetId
        );
        loadBuffetDetails(data);
      } catch (err) {
        setBuffetDetailsError(err);
      }
    }

    setLoading(false);
  };

  const loadBuffetDetails = (data) => {
    setEditing(true);
    setAddress(data.address);
    setDistrict(data.district);
    setEmail(data.email);
    setName(data.name);
    setNumber(data.number);
    setPhone(data.phone);
    setPhone2(data.phone2);
    setWebsite(data.website);
    setWhatsapp(data.whatsapp);
  };

  const clearFormAfterSubmiting = () => {
    setAddress("");
    setDistrict("");
    setEmail("");
    setName("");
    setNumber("");
    setPhone("");
    setPhone2("");
    setWebsite("");
    setWhatsapp("");
  };

  const clearMessages = () => {
    setBuffetDetailsError("");
    setBuffetSaved("");
    setBuffetSaveError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoadingSubmit(true);
    clearMessages();

    const obj = {
      token,
      address,
      phone,
      phone2,
      whatsapp,
      website,
      district,
      email,
      number,
      name,
    };

    if (editing) {
      obj.editing = true;
      obj.buffetId = buffetId;
    }

    try {
      const { message } = await saveNotRegisteredBusiness(obj);
      setBuffetSaved(message);
      clearFormAfterSubmiting();
    } catch (err) {
      setBuffetSaveError(err);
    }

    setLoadingSubmit(false);

    // eslint-disable-next-line no-undef
    window.document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <MasterPage
      title={buffetId ? "Editar Buffet" : "Adicionar Buffet"}
      username={username}
      userRole={userRole}
      breadcrumb={[
        { name: "Buffets não cadastrados", url: "/buffetsNotRegistered" },
        {
          name: buffetId ? "Editar Buffet" : "Adicionar Buffet",
        },
      ]}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {loadingSubmit && <Loader full />}

          {buffetDetailsError ? (
            <AlertMessages
              show={!!buffetDetailsError}
              type="error"
              message={buffetDetailsError}
            />
          ) : (
            <form method="POST" onSubmit={(e) => onSubmit(e)}>
              <AlertMessages
                show={!!buffetSaveError}
                type="error"
                message={buffetSaveError}
              />

              <AlertMessages
                show={!!buffetSaved}
                type="success"
                message={buffetSaved}
              />

              <StatsBlock title="Dados do Buffet">
                <Row cellSpacing={15} rowSpacing={15}>
                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Nome do buffet *"
                      id="txtName"
                      name="name"
                      placeholder="digite o nome do buffet..."
                      maxLength="60"
                      autoComplete="off"
                      onChange={({ target }) => setName(target.value)}
                      value={name}
                      required
                    />
                  </Col>

                  <Col lg={6} md={6} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="E-mail"
                      id="txtEmail"
                      name="email"
                      placeholder="contato@kidsbuffets.com.br"
                      autoComplete="off"
                      onChange={({ target }) => setEmail(target.value)}
                      value={email}
                    />
                  </Col>

                  <Col lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Telefone (Principal)"
                      id="txtPhone"
                      name="phone"
                      mask="(99) 9999-9999"
                      placeholder="(11) 9999-9999"
                      autoComplete="off"
                      onChange={({ target }) => setPhone(target.value)}
                      value={phone}
                    />
                  </Col>

                  <Col lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Telefone (Secundário)"
                      id="txtPhone2"
                      name="phone2"
                      mask="(99) 9999-9999"
                      placeholder="(11) 9999-9999"
                      autoComplete="off"
                      onChange={({ target }) => setPhone2(target.value)}
                      value={phone2}
                    />
                  </Col>

                  <Col lg={4} md={6} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Whatsapp"
                      id="txtWhatsapp"
                      name="whatsapp"
                      mask="(99) 99999-9999"
                      placeholder="(11) 99999-9999"
                      autoComplete="off"
                      onChange={({ target }) => setWhatsapp(target.value)}
                      value={whatsapp}
                    />
                  </Col>

                  <Col lg={9} md={9} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Endereço *"
                      id="txtAddress"
                      name="address"
                      placeholder="digite o endereço do buffet..."
                      onChange={({ target }) => setAddress(target.value)}
                      value={address}
                      required
                    />
                  </Col>

                  <Col lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      type="text"
                      label="Número *"
                      id="txtNumber"
                      name="number"
                      onChange={({ target }) => setNumber(target.value)}
                      value={number}
                      autoComplete="off"
                      placeholder="123"
                      maxLength="6"
                      required
                    />
                  </Col>

                  <Col lg={6} md={12} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Bairro *"
                      id="txtDistrict"
                      name="district"
                      placeholder="digite o nome do bairro..."
                      onChange={({ target }) => setDistrict(target.value)}
                      value={district}
                      required
                    />
                  </Col>

                  <Col lg={6} md={12} sm={12} xs={12}>
                    <TextField
                      type="text"
                      label="Website"
                      id="txtWebsite"
                      name="website"
                      placeholder="https://kidsbuffets.com.br"
                      maxLength="60"
                      autoComplete="off"
                      onChange={({ target }) => setWebsite(target.value)}
                      value={website}
                      pattern={validWebsite(website).regexAsString}
                    />
                  </Col>
                </Row>
              </StatsBlock>

              <div className="registerBuffet--actions">
                <button type="submit" className="btn">
                  {editing ? "Salvar" : "Adicionar"}
                </button>
              </div>
            </form>
          )}
        </>
      )}
    </MasterPage>
  );
};

BuffetNotRegisteredForm.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
};

export default BuffetNotRegisteredForm;
