/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./index.sass";

const Header = ({ username, onHandleToggleMenu }) => (
  <header>
    <i
      className="icon-bars"
      role="button"
      tabIndex={0}
      onClick={() => onHandleToggleMenu()}
      onKeyPress={() => onHandleToggleMenu()}
    />

    {username && (
      <div className="header-info">
        <span className="header-user">{username}</span>
        <i className="icon-user" />

        <ul className="header-usermenu">
          <li>
            <Link to="/logout">Sair</Link>
          </li>
        </ul>
      </div>
    )}
  </header>
);

Header.propTypes = {
  username: PropTypes.string.isRequired,
  onHandleToggleMenu: PropTypes.func.isRequired,
};

export default Header;
