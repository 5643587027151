import React from 'react';
// import PropTypes from 'prop-types';

const Footer = () => (
  <footer />
);

// Footer.propTypes = {

// };

export default Footer;
