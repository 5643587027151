import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import InputFile from "../InputFile";

const InputRender = React.forwardRef(({ type, prefix, ...rest }, ref) => {
  switch (type) {
    case "textarea": {
      return <textarea ref={ref} {...rest} />;
    }

    case "select": {
      return <select ref={ref} {...rest} />;
    }

    case "file": {
      return <InputFile ref={ref} {...rest} />;
    }

    default: {
      if (prefix) {
        return (
          <div className="input-prefix-sufix">
            <span className="input-prefix">{prefix}</span>
            <input type={type} ref={ref} {...rest} />
          </div>
        );
      }

      return <input type={type} ref={ref} {...rest} />;
    }
  }
});

InputRender.propTypes = {
  type: PropTypes.string.isRequired,
};

const TextField = React.forwardRef((props, ref) => {
  const {
    type,
    label,
    prefix,
    id,
    readOnly,
    disabled,
    iconClass,
    className,
    description,
    name,
    mask,
    inputErrors,
    title,
    minLength,
    ...rest
  } = props;

  let errorMessage;
  const errorClassName = inputErrors ? inputErrors[name] : null;

  if (inputErrors && inputErrors[name]) {
    if (inputErrors[name].types.required) {
      errorMessage = "Campo obrigatório.";
    } else if (inputErrors[name].types.minLength) {
      errorMessage = `Mínimo ${minLength} caracteres.`;
    } else {
      errorMessage = title;
    }
  }

  return (
    <>
      <div
        className={`
        field-content
        ${errorClassName ? "field-content-error" : ""}
        ${disabled || readOnly ? "input-not-editable" : ""}
        `}
      >
        {!!label && <label htmlFor={id}>{label}</label>}

        {!!iconClass && <i className={iconClass} />}

        {mask ? (
          <InputMask
            inputRef={ref}
            name={name}
            type={type}
            mask={mask}
            style={label ? { marginTop: "5px" } : null}
            className={`${className} ${iconClass ? "input-has-icon" : ""}`}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            prefix={prefix}
            {...rest}
          />
        ) : (
          <InputRender
            ref={ref}
            name={name}
            type={type}
            style={label ? { marginTop: "5px" } : null}
            className={`${className} ${iconClass ? "input-has-icon" : ""}`}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            prefix={prefix}
            {...rest}
          />
        )}
      </div>

      {!!errorMessage && (
        <small className="field-content-description">{errorMessage}</small>
      )}

      {description && (
        <small
          className="field-content-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </>
  );
});

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  iconClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  description: PropTypes.string,
  minLength: PropTypes.number,
};

TextField.defaultProps = {
  id: "",
  prefix: "",
  label: "",
  iconClass: "",
  mask: "",
  className: "",
  description: "",
  readOnly: false,
  disabled: false,
  minLength: 3,
};

export default TextField;
