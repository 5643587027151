import React, { useEffect, useState, useReducer, createContext } from "react";
import { getBuffetsByUser } from "../actions/buffets";
import useLocalStorage from "../hooks/useLocalStorage";
import globalReducer, {
  UPDATE_BUSINESSES_STATUS,
  ADD_BUSINESSES,
  GET_BUSINESSES,
  GET_BUSINESSES_ERROR,
} from "../reducers/global";

const GlobalContext = createContext();

const initialState = {
  businesses: [],
  businessesError: null,
};

export const GlobalProvider = ({ children, history }) => {
  const [localItem, setLocalItem] = useLocalStorage();
  const [loadingBusinesses, setLoadingBusinesses] = useState(true);
  const [businessesState, businessesDispatch] = useReducer(
    globalReducer,
    initialState
  );

  const parseLocalItem =
    localItem &&
    (typeof localItem === "string" ? JSON.parse(localItem) : localItem);

  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localItem]);

  const getBusinesses = async () => {
    if (
      parseLocalItem &&
      parseLocalItem.token &&
      businessesState.businesses.length === 0
    ) {
      try {
        const { data } = await getBuffetsByUser(parseLocalItem.token);
        businessesDispatch({ type: GET_BUSINESSES, payload: data });
      } catch (err) {
        businessesDispatch({ type: GET_BUSINESSES_ERROR, payload: err });
      }
    }

    setLoadingBusinesses(false);
  };

  function addBusinesses(businessesObj) {
    businessesDispatch({ type: ADD_BUSINESSES, payload: businessesObj });
  }

  function updateBusinessesStatus(businessesId, status) {
    businessesDispatch({
      type: UPDATE_BUSINESSES_STATUS,
      payload: { id: businessesId, status },
    });
  }

  async function addItemLocalStorage(value) {
    await setLocalItem(value);
  }

  return (
    <GlobalContext.Provider
      value={{
        token: parseLocalItem ? parseLocalItem.token : null,
        username: parseLocalItem ? parseLocalItem.username : null,
        userRole: parseLocalItem ? parseLocalItem.userRole : null,
        loadingBusinesses,
        businesses: businessesState.businesses,
        businessesError: businessesState.businessesError,
        addItemLocalStorage,
        addBusinesses,
        updateBusinessesStatus,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const GlobalConsumer = GlobalContext.Consumer;

export default GlobalContext;
