/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react";
import queryString from "query-string";
import moment from "moment";
import Loader from "../../../components/Loader";
import StatsBlock from "../../../components/StatsBlock";
import { Row, Col } from "../../../components/Grid";
import MasterPage from "../../../components/MasterPage";
import Paginator from "../../../components/Paginator";
import TextField from "../../../components/TextField";
import AlertMessages from "../../../components/AlertMessages";
import GlobalContext from "../../../context/global";
import { useUpdateHistory } from "../../../hooks/usePage";
import { getAllBuffets } from "../../../actions/buffets";

const BuffetsNotRegistered = ({ history }) => {
  const { token, username, userRole } = useContext(GlobalContext);

  const [setUpdateHistory] = useUpdateHistory(history);

  const [loading, setLoading] = useState(true);
  const [txtOrderBy, setTxtOrderBy] = useState("total_views");
  const [listOfBuffets, setListOfBuffets] = useState([]);
  const [listOfBuffetsError, setListOfBuffetsError] = useState("");
  const [listOfBuffetsPage, setListOfBuffetsPage] = useState(1);
  const [listOfBuffetsTotal, setListOfBuffetsTotal] = useState(0);

  const limit = 30;

  useEffect(() => {
    getAllBusinesses();
    // eslint-disable-next-line
  }, [history.location.search]);

  const getAllBusinesses = async () => {
    const { page, orderBy } = queryString.parse(history.location.search);

    if (orderBy) {
      setTxtOrderBy(orderBy);
    }

    try {
      const result = await getAllBuffets({
        token,
        limit,
        page: page || listOfBuffetsPage,
        orderBy: orderBy,
      });
      setListOfBuffets(result.data);
      setListOfBuffetsPage(result.currentPage);
      setListOfBuffetsTotal(result.totalItems);
    } catch (err) {
      setListOfBuffetsError(err);
    }

    setLoading(false);
  };

  const onHandleChangePage = async (itemsBeingShowed, page) => {
    setUpdateHistory("page", page);
    getAllBusinesses();
  };

  const currentMonth = `${new Date().getFullYear()}/${
    new Date().getMonth() + 1
  }`;
  const priorMonth = `${new Date().getFullYear()}/${new Date().getMonth()}`;

  return (
    <MasterPage
      title="Todos Buffets Registrados"
      username={username}
      userRole={userRole}
      breadcrumb={[{ name: "Buffets Registrados" }]}
    >
      <AlertMessages
        show={!!listOfBuffetsError}
        type="error"
        message={listOfBuffetsError}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <StatsBlock title="Pesquisa avançada" style={{ marginBottom: 30 }}>
            <Row cellSpacing={15} rowSpacing={0}>
              <Col lg={4} md={12} sm={12} xs={12}>
                <TextField
                  type="select"
                  label="Ordernar por"
                  id="txtOrderBy"
                  name="txtOrderBy"
                  onChange={({ target }) =>
                    setUpdateHistory("orderBy", target.value)
                  }
                  value={txtOrderBy}
                >
                  <option value="total_views">Total de Views</option>
                  {/* <option value="">
                    Views em {moment(currentMonth).format("MM/YYYY")}
                  </option>
                  <option value="">
                    Views em {moment(priorMonth).format("MM/YYYY")}
                  </option> */}
                  <option value="created_at">Data de criação</option>
                  <option value="comments">Comentários</option>
                  <option value="budgets">Orçamentos</option>
                </TextField>
              </Col>
            </Row>
          </StatsBlock>

          <table>
            <thead>
              <tr>
                <th>Data de cadastro</th>
                <th>Nome</th>
                <th>Total Views</th>
                <th>
                  Views ({moment(new Date(priorMonth)).format("MM/YYYY")})
                </th>
                <th>
                  Views ({moment(new Date(currentMonth)).format("MM/YYYY")})
                </th>
                <th>Comentários</th>
                <th>Budgets</th>
              </tr>
            </thead>

            <tbody>
              {listOfBuffets.length > 0 ? (
                <>
                  {listOfBuffets.map((item) => {
                    const getViewsFromCurrentMonth = item.views.find(
                      (view) => view.date === currentMonth
                    );
                    const getViewsFromPriorMonth = item.views.find(
                      (view) => view.date === priorMonth
                    );

                    return (
                      <tr key={item.id}>
                        <td data-label="Data Cadastro" valign="top">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td data-label="Nome" valign="top">
                          {item.name}
                        </td>
                        <td data-label="Total Views" valign="top">
                          {item.total_views}
                        </td>
                        <td
                          data-label={`Views (
                          ${moment(new Date())
                            .subtract(1, "months")
                            .format("MM/YYYY")})`}
                          valign="top"
                        >
                          {getViewsFromPriorMonth
                            ? getViewsFromPriorMonth.value
                            : 0}
                        </td>
                        <td
                          data-label={`Views (${moment(new Date()).format(
                            "MM/YYYY"
                          )})`}
                        >
                          {getViewsFromCurrentMonth
                            ? getViewsFromCurrentMonth.value
                            : 0}
                        </td>
                        <td data-label="Comentários" valign="top">
                          {item.comments}
                        </td>
                        <td data-label="Orçamentos" valign="top">
                          {item.budgets}
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="7" valign="top" align="center">
                    Nenhum buffet encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {listOfBuffets.length > 0 && (
            <Paginator
              itemsBeingShowed={limit}
              currentPage={listOfBuffetsPage}
              totalItems={listOfBuffetsTotal}
              showing={listOfBuffets.length}
              onHandlePage={(itemsBeingShowed, page) =>
                onHandleChangePage(itemsBeingShowed, page)
              }
            />
          )}
        </>
      )}
    </MasterPage>
  );
};

export default BuffetsNotRegistered;
