import { APIResquest } from "../utils/api";
import { REACT_APP_ORIGIN } from "../config/variables";

export function budgetsByBuffetId(token, buffetId, limit = 10, page = 1) {
  return APIResquest({
    uri: "budgets",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
      limit,
      page,
    },
  });
}
