/* eslint-disable no-undef */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import GlobalContext from "../../context/global";

const PublicRoute = ({
  component: Component,
  permission,
  routerProps,
  ...rest
}) => {
  const { token } = useContext(GlobalContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          return <Redirect to="/meus-anuncios" />;
        }

        return (
          <Component
            auth={false}
            routerPermission={permission}
            routerProps={routerProps}
            {...props}
          />
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  permission: PropTypes.instanceOf(Array),
  component: PropTypes.instanceOf(Object).isRequired,
  routerProps: PropTypes.instanceOf(Object),
};

PublicRoute.defaultProps = {
  routerProps: {},
  permission: [],
};

export default PublicRoute;
