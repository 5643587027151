/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Loader from "../../../components/Loader";
import MasterPage from "../../../components/MasterPage";
import Paginator from "../../../components/Paginator";
import StatsBlock from "../../../components/StatsBlock";
import TextField from "../../../components/TextField";
import { Row, Col } from "../../../components/Grid";
import AlertMessages from "../../../components/AlertMessages";
import Lightbox from "../../../components/Lightbox";
import GlobalContext from "../../../context/global";
import {
  getAllNotRegisteredBusiness,
  removeNotRegisteredBusiness,
  sendEmailNotRegisteredBusiness,
} from "../../../actions/buffetsNotRegistered";
import { useUpdateHistory } from "../../../hooks/usePage";
import styles from "./index.module.sass";

const BuffetsNotRegistered = ({ history }) => {
  const { token, username, userRole } = useContext(GlobalContext);

  const [setUpdateHistory] = useUpdateHistory(history);

  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [txtQuantity, setTxtQuantity] = useState("all");
  const [txtBusinesses, setTxtBusinesses] = useState("all");

  const [sendEmailBuffetsSuccess, setSendEmailBuffetsSuccess] = useState("");
  const [sendEmailBuffetsError, setSendEmailBuffetsError] = useState("");

  const [deleteBuffetsSuccess, setDeleteBuffetsSuccess] = useState("");
  const [deleteBuffetsError, setDeleteBuffetsError] = useState("");

  const [openDetailsLightbox, setOpenDetailsLightbox] = useState(false);
  const [openRemoveLightbox, setOpenRemoveLightbox] = useState(false);
  const [openSendEmailLightbox, setOpenSendEmailLightbox] = useState(false);

  const [details, setDetails] = useState({});
  const [removeDetails, setRemoveDetails] = useState({});
  const [sendEmailDetails, setSendEmailDetails] = useState({});

  const [listOfBuffets, setListOfBuffets] = useState([]);

  const [listOfBuffetsError, setListOfBuffetsError] = useState("");
  const [listOfBuffetsPage, setListOfBuffetsPage] = useState(1);
  const [listOfBuffetsTotal, setListOfBuffetsTotal] = useState(0);

  const [limit] = useState(50);

  useEffect(() => {
    getAllBusinesses();
    // eslint-disable-next-line
  }, []);

  const getAllBusinesses = async () => {
    setLoading(true);

    let getPageParams = listOfBuffetsPage;
    let getQuantityParams = txtQuantity;
    let getBusinessesParams = txtBusinesses;

    if (history.location.search) {
      const { page, businesses, quantity } = queryString.parse(
        history.location.search
      );

      if (page) {
        getPageParams = Number(page);
      }

      if (quantity) {
        getQuantityParams = quantity;

        if (txtQuantity !== getQuantityParams) {
          setTxtQuantity(getQuantityParams);
        }
      }

      if (businesses) {
        getBusinessesParams = businesses;

        if (txtBusinesses !== getBusinessesParams) {
          setTxtBusinesses(getBusinessesParams);
        }
      }
    }

    try {
      const {
        data,
        currentPage,
        totalItems,
      } = await getAllNotRegisteredBusiness({
        token,
        limit,
        page: getPageParams,
        quantity: getQuantityParams,
        businesses: getBusinessesParams,
      });

      setListOfBuffets(data);
      setListOfBuffetsPage(currentPage);
      setListOfBuffetsTotal(totalItems);
    } catch (err) {
      setListOfBuffetsError(err);
    }

    setLoading(false);
  };

  const clearMessages = async () => {
    setSendEmailBuffetsSuccess("");
    setSendEmailBuffetsError("");
    setDeleteBuffetsSuccess("");
    setDeleteBuffetsError("");
    setListOfBuffetsError("");
  };

  const onHandleChangePage = async (itemsBeingShowed, page) => {
    setUpdateHistory("page", page);

    clearMessages();
    getAllBusinesses();
  };

  const onHandleOpenDetailsLighbox = (_bool, obj = {}) => {
    clearMessages();

    setOpenDetailsLightbox(_bool);
    setDetails(obj);
  };

  const onHandleRemove = (_bool, obj = {}) => {
    clearMessages();

    setOpenRemoveLightbox(_bool);
    setRemoveDetails(obj);
  };

  const onHandleSendEmail = (_bool, obj = {}) => {
    clearMessages();

    setOpenSendEmailLightbox(_bool);
    setSendEmailDetails(obj);
  };

  const updateEmailsSentCounter = (businessId) => {
    const newArr = listOfBuffets.map((item) => {
      if (item.id === businessId) {
        return {
          ...item,
          emailsSent: item.emailsSent + 1,
        };
      }

      return item;
    });

    setListOfBuffets(newArr);
  };

  const onHandleSendEmailSubmit = async () => {
    setLoadingSubmit(true);
    setOpenSendEmailLightbox(false);
    setSendEmailDetails({});

    try {
      const { message } = await sendEmailNotRegisteredBusiness(
        token,
        sendEmailDetails.id
      );

      updateEmailsSentCounter(sendEmailDetails.id);
      setSendEmailBuffetsSuccess(message);
    } catch (err) {
      setSendEmailBuffetsError(err);
    }

    setLoadingSubmit(false);
  };

  const onHandleRemoveSubmit = async () => {
    setLoadingSubmit(true);
    setOpenRemoveLightbox(false);
    setRemoveDetails({});

    try {
      const { message } = await removeNotRegisteredBusiness(
        token,
        removeDetails.id
      );
      setDeleteBuffetsSuccess(message);
      getAllBusinesses();
    } catch (err) {
      setDeleteBuffetsError(err);
    }

    setLoadingSubmit(false);
  };

  return (
    <MasterPage
      title="Buffets não cadastrados"
      username={username}
      userRole={userRole}
      breadcrumb={[{ name: "Buffets não cadastrados" }]}
      actionsComponent={
        <Link to="/buffetsNotRegistered/add" className="btn small blue-theme">
          Adicionar
        </Link>
      }
    >
      <AlertMessages
        show={!!deleteBuffetsError}
        type="error"
        message={deleteBuffetsError}
      />

      <AlertMessages
        show={!!deleteBuffetsSuccess}
        type="success"
        message={deleteBuffetsSuccess}
      />

      <AlertMessages
        show={!!sendEmailBuffetsSuccess}
        type="success"
        message={sendEmailBuffetsSuccess}
      />

      <AlertMessages
        show={!!sendEmailBuffetsError}
        type="error"
        message={sendEmailBuffetsError}
      />

      <AlertMessages
        show={!!listOfBuffetsError}
        type="error"
        message={listOfBuffetsError}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {loadingSubmit && <Loader full />}

          <StatsBlock title="Pesquisar por" style={{ marginBottom: 30 }}>
            <Row cellSpacing={15} rowSpacing={0}>
              <Col lg={4} md={6} sm={12} xs={12}>
                <TextField
                  type="select"
                  label="Listar Buffets com"
                  id="txtBusinesses"
                  name="txtBusinesses"
                  onChange={({ target }) => {
                    setTxtBusinesses(target.value);
                    setUpdateHistory("businesses", target.value);
                    onHandleChangePage(null, 1);
                  }}
                  value={txtBusinesses}
                >
                  <option value="all">E-mail ou Whatsapp</option>
                  <option value="whatsapp">Apenas Whatsapp</option>
                  <option value="email">Apenas E-mail</option>
                </TextField>
              </Col>

              <Col lg={3} md={6} sm={12} xs={12}>
                <TextField
                  type="select"
                  label="Qtde de e-mails enviados"
                  id="txtQuantity"
                  name="txtQuantity"
                  onChange={({ target }) => {
                    setTxtQuantity(target.value);
                    setUpdateHistory("quantity", target.value);
                    onHandleChangePage(null, 1);
                  }}
                  value={txtQuantity}
                >
                  <option value="all">Todos</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10+">10+</option>
                </TextField>
              </Col>
            </Row>
          </StatsBlock>

          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Whatsapp</th>
                <th>E-mail?</th>
                <th>E-mails enviados</th>
                <th width="400">Ações</th>
              </tr>
            </thead>

            <tbody>
              {listOfBuffets.length > 0 ? (
                <>
                  {listOfBuffets.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td data-label="Nome" valign="top">
                          {item.name}
                        </td>
                        <td data-label="Whatsapp" valign="top">
                          {item.whatsapp ? (
                            <a
                              href={`https://api.whatsapp.com/send?phone=55${item.whatsapp
                                .replace(/-/, "")
                                .replace(/\(/, "")
                                .replace(/\)/, "")
                                .replace(/\s/, "")}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.whatsapp}
                            </a>
                          ) : (
                            <i
                              className={`icon-cancel ${styles.tableIcon} ${styles.tableIconCancel}`}
                            />
                          )}
                        </td>
                        <td data-label="E-mail?" valign="top">
                          {item.email ? (
                            <i
                              className={`icon-check ${styles.tableIcon} ${styles.tableIconCheck}`}
                            />
                          ) : (
                            <i
                              className={`icon-cancel ${styles.tableIcon} ${styles.tableIconCancel}`}
                            />
                          )}
                        </td>
                        <td data-label="E-mails enviados" valign="top">
                          {item.emailsSent || 0}
                        </td>
                        <td data-label="Ações" valign="top">
                          <button
                            type="button"
                            className={`btn gray-theme mini ${styles.btn}`}
                            onClick={() =>
                              onHandleOpenDetailsLighbox(true, item)
                            }
                          >
                            Detalhes
                          </button>

                          <Link
                            to={`/buffetsNotRegistered/edit/${item.id}`}
                            className={`btn mini blue-theme ${styles.btn}`}
                          >
                            Editar
                          </Link>

                          <button
                            type="button"
                            className={`btn red-theme mini ${styles.btn}`}
                            onClick={() => onHandleRemove(true, item)}
                          >
                            Remover
                          </button>

                          <button
                            type="button"
                            className={`btn green-theme mini ${styles.btn}`}
                            onClick={() => onHandleSendEmail(true, item)}
                            disabled={!item.email}
                          >
                            Enviar e-mail
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan="5" valign="top" align="center">
                    Nenhum buffet encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {listOfBuffets.length > 0 && (
            <Paginator
              itemsBeingShowed={limit}
              currentPage={listOfBuffetsPage}
              totalItems={listOfBuffetsTotal}
              showing={listOfBuffets.length}
              onHandlePage={(itemsBeingShowed, page) =>
                onHandleChangePage(itemsBeingShowed, page)
              }
            />
          )}

          <Lightbox
            show={openRemoveLightbox}
            width={700}
            height={240}
            onClose={() => onHandleRemove(false)}
            title="Você tem certeza?"
          >
            <p className={styles.lightboxDescription}>
              {"Você tem certeza que deseja remover o "}
              <strong>{`Buffet Infantil ${removeDetails.name}`}</strong>
              {"?"}
            </p>

            <div className="lightbox-actions">
              <button
                type="button"
                className="btn red-theme small"
                onClick={() => onHandleRemoveSubmit()}
              >
                Confirmar
              </button>

              <button
                type="button"
                className="btn no-theme small"
                onClick={() => onHandleRemove(false)}
              >
                Cancelar
              </button>
            </div>
          </Lightbox>

          <Lightbox
            show={openSendEmailLightbox}
            width={600}
            height={270}
            onClose={() => onHandleSendEmail(false)}
            title="Você tem certeza?"
          >
            <p className={styles.lightboxDescription}>
              {
                "Você tem certeza que deseja enviar um e-mail marketing de cadastro para o "
              }
              <strong>{`Buffet Infantil ${sendEmailDetails.name}`}</strong>
              {"?"}
            </p>

            <div className="lightbox-actions">
              <button
                type="button"
                className="btn green-theme small"
                onClick={() => onHandleSendEmailSubmit()}
              >
                Confirmar
              </button>

              <button
                type="button"
                className="btn no-theme small"
                onClick={() => onHandleSendEmail(false)}
              >
                Cancelar
              </button>
            </div>
          </Lightbox>

          <Lightbox
            show={openDetailsLightbox}
            width={650}
            height={400}
            onClose={() => onHandleOpenDetailsLighbox(false)}
            title={`Buffet Infantil ${details.name}` || "Detalhes do Buffet"}
          >
            {details && Object.entries(details).length > 0 && (
              <table className="mycomments-table vertical-table">
                <tbody>
                  <tr>
                    <td width="125">
                      <strong>E-mails Enviados:</strong>
                    </td>
                    <td>{details.emailsSent || 0}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>E-mail</strong>
                    </td>
                    <td>{details.email || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Telefone:</strong>
                    </td>
                    <td>
                      {details.phone || "-"}
                      {`${details.phone2 ? `/${details.phone2}` : ""}`}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Whatsapp:</strong>
                    </td>
                    <td>{details.whatsapp || "-"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Website:</strong>
                    </td>
                    <td>
                      {details.website ? (
                        <a
                          href={details.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {details.website}
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Endereço:</strong>
                    </td>
                    <td>
                      {details.address}
                      {", "}
                      {details.number}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Distrito:</strong>
                    </td>
                    <td>{details.district || "-"}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </Lightbox>
        </>
      )}
    </MasterPage>
  );
};

export default BuffetsNotRegistered;
