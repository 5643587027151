import { useState } from "react";
import queryString from "query-string";

export function useActiveCurrentBusinesses(history, location) {
  const [activeCurrentBusinesses, setActiveBusinesses] = useState(null);

  function setActiveCurrentBusinesses(currentBusinesses) {
    setActiveBusinesses(currentBusinesses);

    const getUrlParams = new URLSearchParams(location.search);

    if (location.search) {
      if (queryString.parse(location.search).businessId) {
        getUrlParams.set("businessId", currentBusinesses.id);
      } else {
        getUrlParams.append("businessId", currentBusinesses.id);
      }

      if (queryString.parse(location.search).page) {
        getUrlParams.set("page", 1);
      } else {
        getUrlParams.append("page", 1);
      }
    }

    history.push({
      pathname: location.pathname,
      search: `?${getUrlParams.toString()}`,
    });
  }

  return [activeCurrentBusinesses, setActiveCurrentBusinesses];
}

export function useUpdatePageHistory(history, location) {
  function setUpdatePageHistory(_page) {
    const getUrlParams = new URLSearchParams(location.search);

    if (location.search && queryString.parse(location.search).page) {
      getUrlParams.set("page", _page);
    } else {
      getUrlParams.append("page", _page);
    }

    history.push({
      pathname: location.pathname,
      search: `?${getUrlParams.toString()}`,
    });
  }

  return [setUpdatePageHistory];
}

export function useUpdateHistory(history) {
  function setUpdateHistory(property, value) {
    const getUrlParams = new URLSearchParams(history.location.search);

    if (
      history.location.search &&
      queryString.parse(history.location.search)[property]
    ) {
      getUrlParams.set(property, value);
    } else {
      getUrlParams.append(property, value);
    }

    history.push({
      pathname: history.location.pathname,
      search: `?${getUrlParams.toString()}`,
    });
  }

  return [setUpdateHistory];
}
