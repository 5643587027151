/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useContext } from "react";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Loader";
import MasterPage from "../../components/MasterPage";
import Lightbox from "../../components/Lightbox";
import AlertMessages from "../../components/AlertMessages";
import BuffetData from "./buffet";
import BuffetAd from "./ad";
import BuffetSocial from "./social";
import BuffetContact from "./contact";
import BuffetPhotos from "./photos";
import GlobalContext from "../../context/global";
import RegisterContext from "../../context/register";
import { saveBuffet } from "../../actions/buffets";
import "./index.sass";

const RegisterBuffet = ({ match }) => {
  const refForm = useRef();

  const { token, username, addBusinesses, updateBusinessesStatus } = useContext(
    GlobalContext
  );

  const {
    loading,
    buffetDetails,
    buffetDetailsError,
    photosRemoved,
    editing,
    handleSubmit,
  } = useContext(RegisterContext);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [buffetSaveError, setBuffetSaveError] = useState(null);
  const [buffetSaved, setBuffetSaved] = useState(null);

  const [lightboxWarningStatus, setLightboxWarningStatus] = useState(false);

  const onSubmit = async (values) => {
    if (lightboxWarningStatus) {
      setLightboxWarningStatus(false);
    }

    setLoadingSubmit(true);

    // eslint-disable-next-line no-undef
    const formValues = new FormData(refForm.current);

    try {
      const { data, message } = await saveBuffet(formValues, true);

      if (!editing) {
        await addBusinesses(data);
      }

      if (editing && buffetDetails.isActive === false) {
        await updateBusinessesStatus(buffetDetails.id, true);
      }

      setBuffetSaved(message);
    } catch (err) {
      setBuffetSaveError(err);

      // eslint-disable-next-line no-undef
      window.document.body.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    setLoadingSubmit(false);
  };

  return (
    <MasterPage
      title={match.params.buffetId ? "Editar Anúncio" : "Cadastrar Anúncio"}
      username={username}
      breadcrumb={[
        {
          name: match.params.buffetId ? "Editar Anúncio" : "Cadastrar Anúncio",
        },
      ]}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {loadingSubmit && <Loader full />}
          {buffetDetailsError ? (
            <AlertMessages
              show={!!buffetDetailsError}
              type="error"
              message={buffetDetailsError}
            />
          ) : (
            <form method="POST" onSubmit={handleSubmit(onSubmit)} ref={refForm}>
              {buffetSaved && !loadingSubmit && (
                <Redirect
                  to={{
                    pathname: "/meus-anuncios",
                    state: { message: buffetSaved },
                  }}
                />
              )}

              <AlertMessages
                show={!!buffetSaveError}
                type="error"
                message={buffetSaveError}
              />

              {editing && buffetDetails.id && (
                <input type="hidden" name="buffetId" value={buffetDetails.id} />
              )}

              {buffetDetails.ibge && (
                <input type="hidden" name="ibge" value={buffetDetails.ibge} />
              )}

              <input type="hidden" name="token" value={token} />

              <input
                type="hidden"
                name="photosRemoved"
                value={JSON.stringify(photosRemoved)}
              />

              <BuffetData />

              <BuffetContact />

              <BuffetAd />

              <BuffetPhotos />

              <BuffetSocial />

              <div className="registerBuffet--actions">
                {/* {buffetDetails.isActive === false ? (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => setLightboxWarningStatus(true)}
                  >
                    Salvar
                  </button>
                ) : (
                  <button type="submit" className="btn">
                    Salvar
                  </button>
                )} */}

                <button type="submit" className="btn">
                  Salvar
                </button>
              </div>

              <Lightbox
                show={lightboxWarningStatus}
                className="registerBuffet-lightboxStatus"
                width={680}
                height={260}
                onClose={() => setLightboxWarningStatus(false)}
                title="Você deseja continuar?"
              >
                <p className="registerBuffet-lightboxStatus-desc">
                  No momento o anúncio do seu Buffet Infantil encontra-se
                  inativo e não está sendo visível para os usuários. Ao clicar
                  em "Salvar" seu anúncio será salvo e automaticamente publicado
                  no site.
                </p>

                <div className="lightbox-actions">
                  <button
                    type="button"
                    className="btn small"
                    onClick={() => setLightboxWarningStatus(false)}
                  >
                    Salvar e Ativar
                  </button>

                  <button
                    type="button"
                    className="btn small no-theme"
                    onClick={() => setLightboxWarningStatus(false)}
                  >
                    Cancelar
                  </button>
                </div>
              </Lightbox>
            </form>
          )}
        </>
      )}
    </MasterPage>
  );
};

export default RegisterBuffet;
