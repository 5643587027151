/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import queryString from "query-string";
import Loader from "../../components/Loader";
import MasterPage from "../../components/MasterPage";
import BudgetsLightbox from "./lightbox";
import Paginator from "../../components/Paginator";
import { Row, Col } from "../../components/Grid";
import AlertMessages from "../../components/AlertMessages";
import StatsBlock from "../../components/StatsBlock";
import TextField from "../../components/TextField";
import { budgetsByBuffetId } from "../../actions/budgets";
import GlobalContext from "../../context/global";
import {
  useActiveCurrentBusinesses,
  useUpdatePageHistory,
} from "../../hooks/usePage";

const MyBudgets = ({ location, history }) => {
  const { token, username, loading, businesses, businessesError } = useContext(
    GlobalContext
  );

  const [
    activeCurrentBusinesses,
    setActiveCurrentBusinesses,
  ] = useActiveCurrentBusinesses(history, location);

  const [setUpdatePageHistory] = useUpdatePageHistory(history, location);

  const [budgetsLoading, setBudgetsLoading] = useState(false);
  const [budgets, setBudgets] = useState([]);
  const [lightboxStatusOpened, setLightboxStatusOpened] = useState(false);
  const [lightboxStatusObj, setLightboxStatusObj] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemsPage, setTotalItemsPage] = useState(1);

  const limit = 10;

  useEffect(() => {
    loadBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businesses]);

  const loadBusiness = () => {
    const getBusinessId = location.search
      ? queryString.parse(location.search).businessId
      : null;
    const findBusinesses = getBusinessId
      ? businesses.find((item) => item.id === getBusinessId)
      : null;
    const currentBusinesses = findBusinesses ? findBusinesses : businesses[0];

    if (businesses.length > 0) {
      loadBudgets(currentBusinesses);
    }
  };

  const fetchBudgets = async (
    pageToShow,
    currentBusinesses = businesses[0]
  ) => {
    setBudgetsLoading(true);

    const getPageParams =
      location.search && queryString.parse(location.search).page
        ? Number(queryString.parse(location.search).page)
        : currentPage;

    try {
      const pageToSubmit = pageToShow || getPageParams;
      const result = await budgetsByBuffetId(
        token,
        currentBusinesses.id,
        limit,
        pageToSubmit
      );
      setTotalItemsPage(result.totalItems);
      setCurrentPage(result.currentPage);
      setBudgets(result.data);
    } catch (err) {}

    setBudgetsLoading(false);
  };

  const loadBudgets = (currentBusinesses = businesses[0], _page = null) => {
    if (currentBusinesses.budgets > 0) {
      fetchBudgets(_page, currentBusinesses);
    } else {
      setBudgets([]);
      setBudgetsLoading(false);
    }

    setActiveCurrentBusinesses(currentBusinesses);
  };

  const onHandleChangePage = (itemsBeingShowed, _page) => {
    setUpdatePageHistory(_page);
    fetchBudgets(_page, activeCurrentBusinesses);
  };

  const onHandleOpenBudgetsLightbox = (_bool, obj) => {
    setLightboxStatusOpened(_bool);
    setLightboxStatusObj(obj || {});
  };

  const onHandleChangeBusinesses = (businessesId) => {
    const findBusinesses = businesses.find((item) => item.id === businessesId);

    if (findBusinesses) {
      loadBudgets(findBusinesses, 1);
    }
  };

  return (
    <MasterPage
      title="Gerenciar Orçamentos"
      username={username}
      breadcrumb={[{ name: "Gerenciar Orçamentos" }]}
    >
      <AlertMessages
        show={!!businessesError}
        type="error"
        message={businessesError}
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          {activeCurrentBusinesses && businesses.length > 0 && (
            <StatsBlock title="Pesquisar por" style={{ marginBottom: 30 }}>
              <Row cellSpacing={15} rowSpacing={0}>
                <Col lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    type="select"
                    label="Nome"
                    id="businesses"
                    name="businesses"
                    onChange={({ target }) =>
                      onHandleChangeBusinesses(target.value)
                    }
                    value={activeCurrentBusinesses.id}
                  >
                    {businesses.map((item) => (
                      <option key={item.id} value={item.id}>
                        Buffet Infantil {item.name}
                      </option>
                    ))}
                  </TextField>
                </Col>
              </Row>
            </StatsBlock>
          )}
          {budgetsLoading ? (
            <Loader />
          ) : (
            <table>
              <thead>
                <tr>
                  <th width="150">Data</th>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Ações</th>
                </tr>
              </thead>

              <tbody>
                {budgets.length > 0 ? (
                  <>
                    {budgets.map((item) => (
                      <tr key={item._id}>
                        <td data-label="Data" valign="top">
                          {moment(new Date(item.created_at)).format(
                            "DD/MM/YYYY - HH:MM"
                          )}
                        </td>
                        <td data-label="Nome" valign="top">
                          {item.name}
                        </td>
                        <td data-label="E-mail" valign="top">
                          {item.email}
                        </td>
                        <td valign="top">
                          <button
                            type="button"
                            className="btn mini blue-theme"
                            onClick={() =>
                              onHandleOpenBudgetsLightbox(true, item)
                            }
                          >
                            Ver detalhes
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="4" valign="top" align="center">
                      Nenhum orçamento encontrado.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {budgets.length > 0 && (
            <Paginator
              itemsBeingShowed={limit}
              currentPage={currentPage}
              totalItems={totalItemsPage}
              showing={budgets.length}
              onHandlePage={(itemsBeingShowed, page) =>
                onHandleChangePage(itemsBeingShowed, page)
              }
            />
          )}
          <BudgetsLightbox
            show={lightboxStatusOpened}
            onHandleOpenBudgetsLightbox={onHandleOpenBudgetsLightbox}
            budgetDetail={lightboxStatusObj}
          />
        </>
      )}
    </MasterPage>
  );
};

export default MyBudgets;
