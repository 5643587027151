import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';

const FlipSwitch = ({
  onChange, id, value, disabled,
}) => (
  <div
    className={`onoffswitch ${disabled ? 'onoffswitch-disabled' : ''}`}
    title={disabled ? 'Seu buffet infantil ainda está incompleto' : ''}
  >
    <input
      type="checkbox"
      name="onoffswitch"
      className="onoffswitch-checkbox"
      id={`myonoffswitch_${id}`}
      checked={value}
      disabled={disabled}
      onChange={({ target }) => onChange(target.checked)}
    />

    <label
      className="onoffswitch-label"
      htmlFor={`myonoffswitch_${id}`}
    >
      <span className="onoffswitch-inner" />
      <span className="onoffswitch-switch" />
    </label>
  </div>
);

FlipSwitch.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

FlipSwitch.defaultProps = {
  id: '',
  disabled: false,
};

export default FlipSwitch;
