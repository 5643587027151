/* eslint-disable no-undef */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./index.sass";

const Lightbox = (props) => {
  const CurrentLightbox = useRef();

  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);

  useEffect(() => {
    if (props.show) {
      window.addEventListener("resize", updateDimensions);
      window.addEventListener("keydown", keyDownListener);
    }

    return function () {
      window.removeEventListener("resize", updateDimensions);
      window.removeEventListener("keydown", keyDownListener);
    };
  });

  const keyDownListener = (e) => {
    if (e.keyCode === 27 || e.charCode === 27) {
      onHandleCloseModal();
    }
  };

  const updateDimensions = () => {
    const calcW =
      window.innerWidth > props.width ? props.width : window.innerWidth - 40;
    const calcH =
      window.innerHeight > props.height
        ? props.height
        : window.innerHeight + 40;

    setWidth(calcW);
    setHeight(calcH);
  };

  const onHandleCloseModal = () => {
    props.onClose();
  };

  if (props.show) {
    document.body.classList.add("body-overflow");

    return (
      <div className={`lightbox ${props.className}`} ref={CurrentLightbox}>
        <div
          className="lightbox-bg"
          role="presentation"
          onClick={() => onHandleCloseModal()}
          onKeyPress={() => onHandleCloseModal()}
        />

        <div
          className="lightbox-main"
          style={{
            width,
            height,
            marginLeft: (width / 2) * -1,
            marginTop: (height / 2) * -1,
            padding: props.spacing,
          }}
        >
          {props.title && <h1 className="lightbox-title">{props.title}</h1>}

          {props.showCloseButton && (
            <button
              type="button"
              className="lightbox-close"
              onClick={() => onHandleCloseModal()}
            >
              X
            </button>
          )}

          <div className="lightbox-content">{props.children}</div>
        </div>
      </div>
    );
  } else {
    document.body.classList.remove("body-overflow");
    return <></>;
  }
};

Lightbox.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.number,
  showCloseButton: PropTypes.bool,
};

Lightbox.defaultProps = {
  title: "",
  width: 640,
  height: 500,
  show: false,
  className: "",
  spacing: 30,
  showCloseButton: true,
};

export default Lightbox;
