import uuidv1 from "uuid/v1";
import { APIResquest } from "../utils/api";
import { REACT_APP_ORIGIN } from "../config/variables";

export function activationStatus(token, status, buffetId) {
  return APIResquest({
    uri: "activeStatus",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      status,
      buffetId,
    },
  });
}

export function detailsByUser(token, buffetId) {
  return APIResquest({
    uri: "detailsByUser",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
      buffetId,
    },
  });
}

export function saveBuffet(data, saveAndActive) {
  return APIResquest({
    uri: `saveBuffet?saveAndActive=${saveAndActive}`,
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
      "Content-Type": `multipart/form-data; charset=utf-8; boundary=${uuidv1()}`,
    },
    data,
  });
}

export function getBuffetsByUser(token) {
  return APIResquest({
    uri: "buffetsByIDs",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params: {
      token,
    },
  });
}

export function getAllBuffets(params) {
  return APIResquest({
    uri: "super-admin/all-buffets",
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": REACT_APP_ORIGIN,
    },
    params,
  });
}
