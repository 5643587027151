import React, { useState } from "react";
import Helmet from "react-helmet";
import logo from "../../assets/images/kidsbuffet-logo.svg";
import TextField from "../../components/TextField";
import { forgotPassword } from "../../actions/auth";
import AlertMessages from "../../components/AlertMessages";
import "./index.sass";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const clearMessages = () => {
    setSuccess(null);
    setError(null);
  };

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    clearMessages();

    setLoading(true);

    try {
      const { message } = await forgotPassword(username);
      setSuccess(message);
    } catch (err) {
      setError(err);
    }

    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Esqueci minha senha | KidsBuffets Admin</title>
      </Helmet>

      <div id="forgotPassword">
        <div id="box">
          <form onSubmit={(e) => onHandleSubmit(e)}>
            <img src={logo} alt="logo" />

            {!success && (
              <>
                <p className="forgotPassword-desc">
                  Digite seu e-mail no campo abaixo para solicitar a recuperação
                  de senha.
                </p>

                <TextField
                  type="text"
                  name="username"
                  iconClass="icon-user"
                  onChange={({ target }) => setUsername(target.value)}
                  value={username}
                  placeholder="email"
                  autoComplete="off"
                  required
                  disabled={loading}
                  minLength={3}
                />
              </>
            )}

            <AlertMessages
              show={!!error}
              message={error}
              type="error"
              className="forgotPassword-alertMessage-error"
            />

            <AlertMessages
              show={!!success}
              message={success}
              type="success"
              className="forgotPassword-alertMessage"
            />

            {!success && (
              <div id="forgotPassword-actions">
                <button
                  type="submit"
                  className="btn blue-theme"
                  disabled={loading}
                >
                  {loading ? "Carregando..." : "Recuperar senha"}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
