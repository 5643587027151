import Login from "../containers/Login";
import ForgotPassword from "../containers/ForgotPassword";
import RecoverPassword from "../containers/RecoverPassword";
import Logout from "../containers/Logout";
import MyAds from "../containers/MyAds";
import Comments from "../containers/Comments";
import Budgets from "../containers/Budgets";
import RegisterBuffet from "../containers/RegisterBuffet";
import IE from "../containers/IE";

// SUPER ADMIN ROUTERS
import AllBuffets from "../containers/SuperAdmin/AllBuffets";
import BuffetsNotRegistered from "../containers/SuperAdmin/BuffetsNotRegistered";
import BuffetsNotRegisteredForm from "../containers/SuperAdmin/BuffetsNotRegistered/Form";

// CONTEXT
import { RegisterProvider } from "../context/register";

const myRoutes = [
  {
    path: "/",
    active: true,
    private: false,
    component: Login,
    context: null,
    permission: [],
    routerProps: {},
  },
  {
    path: "/navegador-nao-suportado",
    active: true,
    private: false,
    component: IE,
    context: null,
    permission: [],
    routerProps: {},
  },
  {
    path: "/recover-password",
    active: true,
    private: false,
    component: RecoverPassword,
    context: null,
    permission: [],
    routerProps: {},
  },
  {
    path: "/forgot-password",
    active: true,
    private: false,
    component: ForgotPassword,
    context: null,
    permission: [],
    routerProps: {},
  },
  {
    path: "/logout",
    active: true,
    private: true,
    component: Logout,
    context: null,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  // {
  //   path: "/dashboard",
  //   active: true,
  //   private: true,
  //   component: Dashboard,
  //   context: null,
  //   permission: ["Super Admin", "Admin"],
  //   routerProps: {},
  // },
  {
    path: "/meus-anuncios",
    active: true,
    private: true,
    component: MyAds,
    context: null,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  {
    path: "/meus-anuncios/editar/:buffetId",
    active: true,
    private: true,
    component: RegisterBuffet,
    context: RegisterProvider,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  {
    path: "/meus-anuncios/cadastrar",
    active: true,
    private: true,
    component: RegisterBuffet,
    context: RegisterProvider,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  {
    path: "/comentarios",
    active: true,
    private: true,
    component: Comments,
    context: null,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  {
    path: "/orcamentos",
    active: true,
    private: true,
    component: Budgets,
    context: null,
    permission: ["Super Admin", "Admin"],
    routerProps: {},
  },
  {
    path: "/buffetsNotRegistered",
    active: true,
    private: true,
    component: BuffetsNotRegistered,
    permission: ["Super Admin"],
    routerProps: {},
  },
  {
    path: "/all-buffets",
    active: true,
    private: true,
    component: AllBuffets,
    permission: ["Super Admin"],
    routerProps: {},
  },
  {
    path: "/buffetsNotRegistered/add",
    active: true,
    private: true,
    component: BuffetsNotRegisteredForm,
    permission: ["Super Admin"],
    routerProps: {},
  },
  {
    path: "/buffetsNotRegistered/edit/:buffetId",
    active: true,
    private: true,
    component: BuffetsNotRegisteredForm,
    permission: ["Super Admin"],
    routerProps: {},
  },
];

export default myRoutes;
