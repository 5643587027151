import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Loader from "../../components/Loader";

const Logout = () => {
  useEffect(() => {
    clearData();
  }, []);

  function clearData() {
    localStorage.clear();
    setInterval(() => {
      window.location.href = "/";
    }, 700);
  }

  return (
    <>
      <Helmet>
        <title>Logout | KidsBuffets Admin</title>
      </Helmet>

      <Loader full />
    </>
  );
};

export default Logout;
